body,
html h1 {
  margin: 0;
  padding: 0;
  width: 100%;
  height: auto;
  zoom: 100%;
}
.main-layout {
  display: flex;
  justify-content: space-between;
  width: 100%;
  min-height: 100vh;
  /* background-image: url("./assets/BG.png"); */
  background-color: #000000;
  background-size: cover;
  background-position: center;
  position: relative;
  z-index: 1;
}

/* Video Background */
.background-video {
  position: fixed;

  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: fill;
  z-index: -1;
}

.mobile-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("./assets/MobileBG.png");
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  z-index: -1;
}
.container {
  margin-left: 220px;
  margin-right: 220px;
  padding: 20px;
  position: relative;
  border-radius: 8px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  max-width: 900px;
  width: 100%;
  margin: 10px auto;
  padding: 20px;
  overflow: hidden;
  justify-content: center;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  max-width: 920px;
  width: 100%;
  margin: 10px auto;
  padding: 20px;
  overflow: hidden;
}
/*
.center-content {
  flex: 1;
  margin-left: 220px;
  margin-right: 220px;
  padding: 20px;
  box-sizing: border-box;
}
*/
/* Intro Images */

.coverRow {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin-top: 20px;
}

.gba-wrapper {
  position: relative;
  display: inline-block;
  width: 200px;
  height: 200px;
  margin-bottom: 40px;
}

.GBA {
  width: 100%;
  height: 100%;
}

.shadow-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

footer {
  text-align: center;
  padding: 10px;
  background-color: #333;
  color: white;
  position: fixed;
  bottom: 0;
  width: 100%;
}

.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

* {
  box-sizing: border-box;
}
/* Main Content container */

.content-wrapper {
  max-width: 800px;
  margin-top: 20px;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
}

.intro-text {
  padding: 30px 70px 30px 70px;
  max-width: 800px;
  font-family: "Avenir Next";
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  width: 100%;
}

.intro-text ul {
  margin-bottom: 2%;
  padding-left: 10%;
  padding-right: 10%;
}
.intro-text li {
  margin-bottom: 2%;
}

.intro-text ul li::marker:nth-child(1) {
  list-style-image: url("./assets/ball.svg");
  transform: scale(0.1);
}

ul.introList {
  list-style: none;
  padding-left: 0;
  margin: 0;
  margin-bottom: 2%;
  padding-left: 0%;
  padding-right: 10%;
}

/* Style list items */
ul.introList li {
  position: relative;
  padding-left: 40px;
  margin-bottom: 10px;
}

/* Insert and style the custom marker */
ul.introList li::before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 20px;
  height: 20px;
  background-image: url("./assets/ball.svg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

/* Table inside Type Description */
.type-description table {
  width: 100%;
}

.type-description th,
.type-description td {
  padding: 10px;
  text-align: center;
  background-clip: padding-box;
}
/* TV Section */

.TV {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 800px;
  width: 100%;
  margin: 0 auto;
  position: relative; /* Needed for positioning the video-section */
  text-align: center;
  z-index: 1;
  margin-left: 0.4%;
}

.TV img {
  max-width: 800px;
  height: auto;
  display: flex;
}

/*
.TV::before {
  content: "";
  position: absolute;
  bottom: -130px; 
  width: 150%; /
  height: 200px; 
  border-radius: 20%;
  clip-path: polygon(20% 0%, 80% 0%, 100% 100%, 0% 100%);
  z-index: -1; /*
  background: linear-gradient(
    rgba(0, 0, 0, 0.9) 20%,
    rgba(12, 12, 12, 0.9) 30%,
    rgba(22, 22, 22, 0.9) 40%,
    rgba(32, 32, 32, 0.9) 50%,
    rgba(42, 42, 42, 0.7) 60%,
    rgba(55, 55, 55, 0.5) 70%,
    rgba(58, 58, 58, 0.3) 80% 
  ); 
} */

.video-section {
  position: absolute;
  top: 22.2%; /* Fit into TV */
  left: 15.73%; /* Fit into TV */
  width: 68%; /* Video area */
  height: 40.5%; /* Video area */
  justify-content: center;
}

.video-section iframe,
.video-section video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  pointer-events: auto;
  border: none;
  justify-content: center;
}

@media (max-width: 1024px) {
  .main-layout {
    flex-direction: column;
    background-image: url("./assets/MobileBG.png");
    background-color: #111;
    min-height: 100vh; /* Ensure the container has height */
    background-size: contain; /* Try 'contain', 'auto', or '100% 100%' */
    background-repeat: no-repeat;
    object-fit: fill;
  }
  .background-video {
    display: none;
  }
  .sidebar-left,
  .sidebar-right {
    display: none;
  }
  .mobile-header {
    display: flex;
    justify-content: center;
    width: 100%;
  }
  .content-wrapper {
  }
  .TV img {
    max-width: 800px;
    height: auto;
    display: flex;
  }
  .TV {
    margin-top: 120px;
  }
  .coverRow {
    margin-bottom: 30px;
  }
  .intro-text {
    padding: 10px 20px 10px 20px;
  }
  .intro-text p {
    padding: 00px 20px 0px 20px;
  }
}
@media (max-width: 820px) {
  .main-layout {
    flex-direction: column;
    background-image: url("./assets/MobileBG.png");
    background-color: #111;
  }
  .background-video {
    display: none;
  }
  .TV img {
    max-width: 770px;
    height: auto;
    display: flex;
  }
  .TV {
    margin-top: 90px;
  }
  .content-wrapper {
    width: 100%;
  }
}
@media (max-width: 768px) {
  .TV img {
    max-width: 730px;
    height: auto;
    display: flex;
  }
  .TV {
    margin-top: 90px;
  }
  .coverRow {
    margin-bottom: 30px;
  }
  .intro-text {
    padding: 10px 20px 10px 20px;
  }
  .intro-text p {
    padding: 00px 20px 0px 20px;
  }
}
@media (max-width: 480px) {
  .TV img {
    max-width: 400px;
    height: auto;
    display: flex;
  }
  .TV {
    margin-top: 50px;
  }
  .intro-text {
    padding: 10px 20px 10px 20px;
  }
  .intro-text p {
    padding: 00px 20px 0px 20px;
  }
}

@media (max-width: 430px) {
  .background-video {
    display: none;
  }
  .main-layout {
    flex-direction: column;
    background-image: url("./assets/MobileBG.png");

    background-color: #111;
    min-height: 100vh; /* Ensure the container has height */
    background-size: contain; /* Try 'contain', 'auto', or '100% 100%' */
    background-repeat: no-repeat;
    object-fit: fill;
  }

  .TV img {
    max-width: 370px;
    height: auto;
    display: flex;
  }
  .TV {
    margin-top: 50px;
  }
  .coverRow {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 30px;
  }

  .gba-wrapper {
    position: relative;
    display: inline-block;
    width: 100px;
    height: 100px;
  }
  .intro-text {
    padding: 10px 20px 10px 20px;
  }
  .intro-text p {
    padding: 01px 20px 0px 20px;
  }
}

@media (max-width: 390px) {
  .background-video {
    display: none;
  }
  .main-layout {
    flex-direction: column;
    background-image: url("./assets/MobileBG.png") !important;
    background-color: #111;
  }
  .TV img {
    max-width: 330px;
    height: auto;
    display: flex;
  }
  .TV {
    margin-top: 50px;
  }

  .content-wrapper {
    padding-left: 10px;
    padding-right: 10px;
  }
  .coverRow {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 30px;
  }

  .gba-wrapper {
    position: relative;
    display: inline-block;
    width: 100px;
    height: 100px;
    margin-bottom: 20px;
  }

  /*.TV::before {
    content: "";
    position: absolute;
    bottom: -130px; 
    width: 150%; 
    height: 200px; 
    border-radius: 20%;
    clip-path: polygon(20% 0%, 80% 0%, 100% 100%, 0% 100%); 
    z-index: -1; 
    background: linear-gradient(
      rgba(0, 0, 0, 0.9) 20%,
      rgba(12, 12, 12, 0.9) 30%,
      rgba(22, 22, 22, 0.9) 40%,
      rgba(32, 32, 32, 0.9) 50%,
      rgba(42, 42, 42, 0.7) 60%,
      rgba(55, 55, 55, 0.5) 70%,
      rgba(58, 58, 58, 0.3) 80%
    ); 
  } */
}

@media (max-width: 375px) {
  .TV img {
    max-width: 320px;
    height: auto;
    display: flex;
  }
  .TV {
    margin-top: 50px;
  }
}
