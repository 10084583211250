/* Fonts */

@import url("https://fonts.cdnfonts.com/css/born2bsporty-fs");

@import url("https://fonts.googleapis.com/css2?family=Protest+Strike&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Permanent+Marker&display=swap");

@font-face {
  font-family: "Pokemon Fire Red";
  src: url("../public/fonts/Pokemon-Fire-Red.woff2") format("woff2");

  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Avenir Next";
  src: url("../public/fonts/AvenirNextLTPro-Regular.woff2") format("woff2");
}
@font-face {
  font-family: "Avenir Next";
  src: url("../public/fonts/AvenirNextLTPro-Bold.woff2") format("woff2");
  font-weight: bold;
}
body::-webkit-scrollbar {
  width: 12px;
}

body::-webkit-scrollbar-thumb {
  background: var(--scrollbar-thumb-color);
  border-radius: 4px;
}

body::-webkit-scrollbar-track {
  background: var(--scrollbar-track-color);
}
/* Light Theme Variables */
:root {
  --scrollbar-thumb-color: #3ba296;
  --scrollbar-track-color: #1e5f57;
  .intro-text h1 {
    padding: 0;
    padding-bottom: 20px;
  }

  .sidebar-right::-webkit-scrollbar {
    width: 8px;
  }
  .sidebar-right::-webkit-scrollbar-track {
    background: #3ba296;
  }
  .sidebar-right::-webkit-scrollbar-thumb {
    background-color: #1e5f57;
    border-radius: 4px;
  }
  .sidebar-right::-webkit-scrollbar-track:end:decrement {
    height: 2%;
    display: block;
    background: transparent;
  }
  .sidebar-right::-webkit-scrollbar-track:start:increment {
    height: 25%;
    display: block;
    background: transparent;
  }

  .nav-button {
    display: none;
  }
  .intro-text h1 {
    padding: 10px;
    color: #fff;
    text-align: center;
    border-radius: 15px;
    font-family: "Born2bSporty FS";
    font-size: 89px;
    text-decoration: none;
    margin-bottom: 3vw;
    margin-top: 1vw;
    text-shadow: 4px 4px 0px black,
      /* Bottom-right dark shadow */ 0px 4px 4px black,
      /* Directly underneath shadow */ 4px 4px 0px black;
  }
  .faq-container {
    margin-top: 0px;
  }
  header h1 {
    color: #eee;
    font-size: 28px;
    font-weight: 700;
    text-transform: uppercase;
  }

  .faqs .faq {
    background-color: #2b8378; /* Adjusted teal background color */
    background-image: linear-gradient(#3ba296 50%, transparent 50%);
    background-size: 100% 2px; /* Mimic the horizontal lines */
    background-position: 0 0, 0 8px;
    border: 4px solid #1e5f57;
  }

  .faqs .faq .faq-question {
    position: relative;

    font-size: 28px;

    background-color: #e7c16f; /* The yellowish background color */
    border: 2px solid #7b6340; /* Brown border for the outer box */
    border-radius: 4px; /* Rounded corners similar to the reference */
    padding: 20px;
    padding-left: 30px;

    position: relative;
    box-shadow: inset 0px -3px 0px #c1844e,
      /* Shadow for the bottom bar */ 0px 0px 8px rgba(0, 0, 0, 0.3);
    font-family: "Pokemon Fire Red", sans-serif;
    color: white;
    text-decoration: none;
    text-shadow: 2px 0px 0px black,
      /* Bottom-right dark shadow */ 0px 2px 0px black,
      /* Directly underneath shadow */ 2px 2px 0px black;
  }

  .faqs .faq.open .faq-answer {
    background-color: #f9d075; /* Color of the border */
    padding: 10px;
    border: 2px solid #7b6340; /* Outer grey border */
    border-radius: 12px;
  }

  .faqs .faq.open .faq-answer p {
    font-size: 16px;
    font-family: "Avenir Next";
    color: black;
    padding: 20px;

    background-color: #ffffce; /* Inner cream-colored container */
    border: 2px solid #d1af48;
    box-shadow: inset 0px 0px 8px rgba(150, 125, 52, 1);
  }

  /* Header */

  .headerBG {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background-color: #1e5f57;
    z-index: 0;
    display: flex;
    height: 88px;
  }

  .mobile-header {
    display: flex;
    justify-content: center;
    background-color: #2b8378;
    background-image: linear-gradient(#3ba296 50%, transparent 50%);
    background-size: 100% 2px;
    background-position: 0 0, 0 8px;
    position: fixed;
    z-index: 10;
    margin-bottom: 50px;
    border: 4px solid #1e5f57;
    border-radius: 15px;
  }
  .mobile-nav-image:hover,
  .mobile-nav-image.active {
    filter: drop-shadow(0 0 15px rgba(209, 175, 72, 0.8));
  }

  /* Types */

  .type-description {
    padding: 20px 20px 20px 20px;
    max-width: 800px;
    background-color: #e7c16f; /* The yellowish background color */
    border: 2px solid #7b6340; /* Brown border for the outer box */
    position: relative;
    box-shadow: inset 0px -3px 0px #c1844e,
      /* Shadow for the bottom bar */ 0px 0px 8px rgba(0, 0, 0, 0.3);
    font-family: "Pokemon Fire Red", sans-serif;

    font-size: 16px;
    font-family: "Avenir Next";

    color: black;
    width: 100%;
  }

  .type-description .descriptionCont {
    margin: 0px;
    padding: 15px;
    padding-left: 50px;
    padding-right: 50px;
    color: black;
    background-color: #ffffce; /* Inner cream-colored container */
    border: 2px solid #d1af48;
    box-shadow: inset 0px 0px 8px rgba(150, 125, 52, 1);
  }

  .type-description p {
    margin: 10px 0;
    color: black;
  }
}
/* Intro */

.content {
  font-family: "Avenir Next";
  background-color: #e7c16f; /* The yellowish background color */
  border: 2px solid #7b6340;
  box-shadow: inset 0px -3px 0px #c1844e,
    /* Shadow for the bottom bar */ 0px 0px 8px rgba(0, 0, 0, 0.3);
  width: 100%;
  color: black;
  padding: 20px 20px 20px 20px;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
}
.intro-text {
  background-color: #ffffce; /* Inner cream-colored container */
  border: 2px solid #d1af48;
  box-shadow: inset 0px 0px 8px rgba(150, 125, 52, 1);
  padding: 15px;
  padding-left: 50px;
  padding-right: 50px;
  color: black;
}
.intro-text p {
  color: black;
}
ul.introList li::before {
  filter: brightness(0) invert(0);
}

/* Audio Player */

.audio-player {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-radius: 10px;
}

/* Track Information */
.track-info {
  text-align: center;
  margin-bottom: 20px;
}

.track-name {
  font-size: 20px;
  font-weight: bold;
  color: #fff;
  margin: 0;
}

.track-artist {
  font-size: 16px;
  color: #ddd;
  margin: 0;
}

/* Controls Container */
.controls-container {
  display: flex;
  align-items: center;
  width: 100%;
}

/* Play/Pause Button */
.play-button {
  background-color: transparent;
  border: none;
  color: #111;
  font-size: 24px;
  cursor: pointer;
  margin-right: 20px;
}

.play-button:focus {
  outline: none;
}

/* Progress Bar */
.progress-container {
  flex: 1;
  height: 5px;
  background-color: #444;
  cursor: pointer;
  border-radius: 5px;
  overflow: hidden;
  position: relative;
}

.progress-bar {
  height: 100%;
  background-color: #e7c16f;
  width: 0%;
}

/* Volume Control */
.volume-container {
  display: flex;
  align-items: center;
  margin-left: 20px;
}

.volume-button {
  background-color: transparent;
  border: none;
  color: #111;
  font-size: 18px;
  cursor: pointer;
}

.volume-button:focus {
  outline: none;
}

.volume-slider {
  width: 80px;
  margin-left: 10px;
}

/* Volume Slider Styling */
.volume-slider {
  -webkit-appearance: none;
  appearance: none;
  background: transparent;
}

.volume-slider:focus {
  outline: none;
}

.volume-slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  height: 12px;
  width: 12px;
  background: #111;
  border-radius: 50%;
  cursor: pointer;
  margin-top: -4px;
}

.volume-slider::-webkit-slider-runnable-track {
  height: 4px;
  background: #111;
  border-radius: 5px;
}

.volume-slider::-moz-range-thumb {
  height: 12px;
  width: 12px;
  background: #111;
  border-radius: 50%;
  cursor: pointer;
}

.volume-slider::-moz-range-track {
  height: 4px;
  background: #111;
  border-radius: 5px;
}

.volume-slider::-ms-thumb {
  height: 12px;
  width: 12px;
  background: #111;
  border-radius: 50%;
  cursor: pointer;
}

/* MOBILE STYLING YO */

@media (max-width: 1024px) {
  :root {
    .body {
    }
    .intro-text h1 {
      padding: 0;
      padding-bottom: 20px;
    }

    .content {
      font-family: "Avenir Next";
      background-color: #e7c16f; /* The yellowish background color */
      border: 2px solid #7b6340;
      width: 100%;
      color: black;
      box-shadow: inset 0px -3px 0px #c1844e;
      padding: 20px 20px 20px 20px;
    }
    .intro-text {
      background-color: #ffffce; /* Inner cream-colored container */
      border: 2px solid #d1af48;
      box-shadow: inset 0px 0px 8px rgba(150, 125, 52, 1);
      padding: 15px;
      padding-left: 50px;
      padding-right: 50px;
      color: black;
    }
    .intro-text p {
      color: black;
    }
    ul.introList li::before {
      filter: brightness(0) invert(0);
    }

    .faqs {
      margin-top: 130px;
      width: 100%;
      max-width: 80%;
      padding: 0px;
    }
    .faqs .faq.open .faq-answer {
      max-height: 1000px;
      opacity: 1;
      background-color: #f9d075; /* Color of the border */
      padding: 10px;
      border: 2px solid #7b6340; /* Outer grey border */
      border-radius: 12px;
      padding-left: 10px;
    }
    .content-wrapper {
      width: 100%;
    }
  }
  @media (min-width: 769px) and (max-width: 840px) {
    .intro-text h1 {
      padding: 0;
      padding-bottom: 20px;
    }

    .faqs {
      margin-top: 130px;
      width: 100%;
      max-width: 80%;
      padding: 0px;
    }
    .faqs .faq.open .faq-answer {
      max-height: 1000px;
      opacity: 1;
      background-color: #f9d075; /* Color of the border */
      padding: 10px;
      border: 2px solid #7b6340; /* Outer grey border */
      border-radius: 12px;
      padding-left: 0px;
    }
    .content-wrapper {
      width: 100%;
    }
    .TV img {
      max-width: 770px;
      height: auto;
      display: flex;
    }
    .TV {
      margin-top: 90px;
    }
  }
  @media (max-width: 768px) {
    :root {
      .body {
      }
      .faqs {
        margin-top: 130px;
        width: 100%;
        max-width: 80%;
        padding: 0px;
      }
      .faqs .faq.open .faq-answer {
        max-height: 1000px;
        opacity: 1;
        background-color: #f9d075;
        padding: 0px;
        border: 2px solid #7b6340;
        border-radius: 12px;
        padding: 0px;
        padding-left: 10px;
        padding-right: 10px;
      }
      .content-wrapper {
        width: 100%;
      }
      .intro-text h1 {
        padding: 0;
        padding-bottom: 20px;
        font-size: 80px;
      }
    }
  }
}
@media (max-width: 480px) {
  :root {
    .body {
    }
    .faqs .faq.open .faq-answer {
      max-height: 1000px;
      opacity: 1;
      background-color: #f9d075;
      padding: 0px;
      border: 2px solid #7b6340;
      border-radius: 12px;
      padding: 0px;
      padding-left: 10px;
      padding-right: 10px;
    }
    .mobile-nav-image:hover,
    .mobile-nav-image.active {
      filter: drop-shadow(0 0 15px rgba(209, 175, 72, 0.8));
    }
  }
}
@media (min-width: 412px) and (max-width: 430px) {
  :root {
    .faqs {
      margin-top: 90px;
      width: 100%;
      max-width: 100%;
      padding: 0px;
    }
    .faqs .faq .faq-question {
      position: relative;

      font-size: 24px;
      padding: 10px;
      padding-bottom: 15px;
    }
    .faqs .faq.open .faq-answer {
      max-height: 1000px;
      opacity: 1;
      background-color: #f9d075;
      border: 2px solid #7b6340;
      border-radius: 12px;
      padding: -10px;
      padding-left: 10px;
      padding-right: 10px;
    }
    .faqs .faq.open .faq-answer p {
      font-size: 14px;
      font-family: "Avenir Next";
      max-height: 1000px;
      opacity: 1;
      background-color: #ffffce;
      border: 2px solid #d1af48;
      box-shadow: inset 0px 0px 8px rgba(150, 125, 52, 1);
    }
    .mobile-nav-image:hover,
    .mobile-nav-image.active {
      filter: drop-shadow(0 0 15px rgba(209, 175, 72, 0.8));
    }
    .content-wrapper {
      width: 100%;
    }
    .TV img {
      max-width: 390px;
      height: auto;
      display: flex;
    }
    .TV {
      margin-top: 50px;
    }
    .intro-text {
      padding: 8px 10px 10px 8px;
    }
    .intro-text p {
      padding: 0px 20px 0px 20px;
    }
    .intro-text li {
      margin-top: 10px;
    }
    .video-section {
      position: absolute;
      top: 22.2%; /* Fit into TV */
      left: 14%; /* Fit into TV */
      width: 71%; /* Video area */
      height: 40.5%; /* Video area */
      justify-content: center;
    }
    .intro-text h1 {
      padding: 0;
      padding-bottom: 20px;
      font-size: 80px;
    }
  }
}
@media (min-width: 390px) and (max-width: 400px) {
  :root {
    .body {
    }

    .intro-text p {
      color: black;
    }
    .intro-text h1 {
      padding: 0;
      padding-bottom: 20px;
      font-size: 55px;
    }
    ul.introList li::before {
      filter: brightness(0) invert(0);
    }
    .content-wrapper {
      width: 100%;
    }

    .faqs {
      margin-top: 50px;
      width: 100%;
      max-width: 100%;
      padding: 0px;
    }
    .faqs .faq .faq-question {
      position: relative;
      padding: 10px;
      font-size: 22px;
      padding-bottom: 20px;
    }
    .faqs .faq.open .faq-answer {
      max-height: 1000px;
      opacity: 1;
      background-color: #f9d075;
      padding: 0px;
      padding-left: 10px;
      padding-right: 10px;

      border: 2px solid #7b6340;
      border-radius: 12px;
    }

    .faqs .faq.open .faq-answer p {
      font-size: 14px;
      font-family: "Avenir Next";
      max-height: 1000px;
      opacity: 1;
      color: black;
      background-color: #ffffce;
      border: 2px solid #d1af48;
      box-shadow: inset 0px 0px 8px rgba(150, 125, 52, 1);
    }
    .type-description {
      padding: 10px;
    }
    .type-description p {
      padding: 0px;
      padding-left: 0px;
      padding-right: 0px;

      font-size: 14px;
    }
    .type-description .descriptionCont {
      padding-left: 20px;
      padding-right: 20px;
    }
    .mobile-nav-image:hover,
    .mobile-nav-image.active {
      filter: drop-shadow(0 0 15px rgba(209, 175, 72, 0.8));
    }
    .intro-text {
      padding: 8px 10px 10px 8px;
    }
    .intro-text p {
      padding: 0px 10px 0px 10px;
    }
    .intro-text li {
      margin-top: 10px;
    }
    .intro-text h1 {
      padding: 0;
      padding-bottom: 20px;
      font-size: 55px;
    }
  }
}
@media (max-width: 390px) {
  :root {
    .body {
    }

    .intro-text p {
      color: black;
    }
    .intro-text h1 {
      padding: 0;
      padding-bottom: 20px;
      font-size: 55px;
    }
    ul.introList li::before {
      filter: brightness(0) invert(0);
    }
    .content-wrapper {
      width: 100%;
    }

    .faqs {
      margin-top: 50px;
      width: 100%;
      max-width: 100%;
      padding: 0px;
    }
    .faqs .faq .faq-question {
      position: relative;
      padding: 10px;
      font-size: 22px;
      padding-bottom: 20px;
    }
    .faqs .faq.open .faq-answer {
      max-height: 1000px;
      opacity: 1;
      background-color: #f9d075;
      padding: 0px;
      padding-left: 10px;
      padding-right: 10px;

      border: 2px solid #7b6340;
      border-radius: 12px;
    }

    .faqs .faq.open .faq-answer p {
      font-size: 14px;
      font-family: "Avenir Next";
      max-height: 1000px;
      opacity: 1;
      color: black;
      background-color: #ffffce;
      border: 2px solid #d1af48;
      box-shadow: inset 0px 0px 8px rgba(150, 125, 52, 1);
    }
    .type-description {
      padding: 10px;
    }
    .type-description p {
      padding: 0px;
      padding-left: 0px;
      padding-right: 0px;

      font-size: 14px;
    }
    .type-description .descriptionCont {
      padding-left: 20px;
      padding-right: 20px;
    }
    .mobile-nav-image:hover,
    .mobile-nav-image.active {
      filter: drop-shadow(0 0 15px rgba(209, 175, 72, 0.8));
    }
    .intro-text {
      padding: 8px 10px 10px 8px;
    }
    .intro-text p {
      padding: 0px 10px 0px 10px;
    }
    .intro-text li {
      margin-top: 10px;
    }
  }

  @media (max-width: 375px) {
    :root {
      .TV img {
        max-width: 300px;
        height: auto;
        display: flex;
      }
      .faqs {
        margin-top: 50px;
        width: 100%;
        max-width: 100%;
        padding: 0px;
      }
      .faqs .faq .faq-question {
        position: relative;

        font-size: 18px;
        padding: 10px;
        padding-bottom: 15px;
        padding-right: 25px;
      }
      .faqs .faq.open .faq-answer {
        max-height: 1000px;
        opacity: 1;
        background-color: #f9d075;
        padding: 0px;
        border: 2px solid #7b6340;
        border-radius: 12px;
        padding: 0px;
        padding-left: 10px;
        padding-right: 10px;
      }
      .faqs .faq.open .faq-answer p {
        font-size: 14px;
        max-height: 1000px;
        opacity: 1;
        box-shadow: none;
      }
      .mobile-nav-image {
        width: 70px;
        height: auto;
      }
      .mobile-nav-image:hover,
      .mobile-nav-image.active {
        filter: drop-shadow(0 0 15px rgba(209, 175, 72, 0.8));
      }
      .headerBG {
        height: 79px;
      }
      .type-description {
        padding: 5px;
      }
      .type-description p {
        padding: 5px;
        padding-left: 5px;
        padding-right: 5px;

        font-size: 14px;
      }
      .type-description .descriptionCont {
        padding-left: 5px;
        padding-right: 5px;
      }
      .type-rows {
        margin-top: 10px;
        width: 110%;
      }
      .type-image {
        max-width: 34px;
        margin: 0px;
      }
      .intro-text p {
        padding: 0px 10px 0px 10px;
      }
      .TV {
        margin-top: 50px;
      }
      .content-wrapper {
        width: 100%;
      }
    }
  }
  @media (min-width: 340px) and (max-width: 360px) {
    :root {
      .TV {
      }
      .TV img {
        max-width: 300px;
        height: auto;
        display: flex;
      }
    }
  }
}

/* ******************************** */
/* ******************************** */
/* ******************************** */
/* ******************************** */
/* ******************************** */
/* ******************************** */
/* ******************************** */
/* ******************************** */
/* ******************************** */
/* ******************************** */
/* ******************************** */
/* ******************************** */
/* ******************************** */
/* ******************************** */
/* ******************************** */
/* ******************************** */

/* Dark Theme Overrides */
.dark-mode {
  --scrollbar-thumb-color: #666;
  --scrollbar-track-color: #111111f2;
  .body {
  }

  .main-layout {
    background-image: url("./assets/RetroBG.avif") !important;
  }

  .sidebar-right::-webkit-scrollbar {
    width: 6px;
  }
  .sidebar-right::-webkit-scrollbar-track {
    background: transparent;
  }
  .sidebar-right::-webkit-scrollbar-thumb {
    background-color: #666;
    border-radius: 4px;
  }
  /* Intro */
  .nav-button {
    display: none;
  }
  .intro-text h1 {
    padding: 10px;
    color: #fff;
    text-align: center;
    border-radius: 15px;
    font-family: "Born2bSporty FS";
    font-size: 89px;
    text-decoration: none;
    margin-bottom: 2vw;
    margin-top: 1vw;
    text-shadow: 4px 4px 0px black,
      /* Bottom-right dark shadow */ 0px 4px 4px black,
      /* Directly underneath shadow */ 4px 4px 0px black;
  }

  .content {
    background: #111111f2;
    font-family: "Noto Sans", sans-serif;
    box-shadow: none;
    border: 2px solid #333;
    width: 100%;

    background-color: none;
    width: 100%;
    color: white;

    padding: 20px 20px 20px 20px;
  }
  .intro-text {
    background: rgba(34, 34, 34, 0);
    border: none;
    box-shadow: none;
    padding: 15px;
    padding-left: 50px;
    text-shadow: 2px 2px 0px black,
      /* Bottom-right dark shadow */ 2px 1px 2px black,
      /* Directly underneath shadow */ 2px 2px 0px black;
    padding-right: 50px;
  }
  .intro-text p {
    color: #f2f3f5;
  }
  ul.introList li::before {
    filter: brightness(0) invert(1);
  }
  .intro-text h1 {
    padding: 0;
    padding-bottom: 20px;
  }

  /* FAQs */
  .faqs .faq {
    background: rgba(34, 34, 34, 0.9);

    border: 2px solid black;
  }

  .faqs .faq .faq-question {
    font-size: 24px;

    background: rgba(34, 34, 34, 0.9);
    border: 2px solid black;
    border-radius: 4px;
    padding: 20px;
    box-shadow: none;
    font-family: "Born2bSporty FS";

    color: white;
  }

  .faqs .faq .faq-answer {
    border: none;
    background: rgba(34, 34, 34, 0.9);

    border-radius: 0;
    margin-left: -10px;
  }

  .faqs .faq.open .faq-question {
    margin-bottom: 15px;
    font-family: "Born2bSporty FS";
  }

  .faqs .faq.open .faq-question::after {
    content: "-";
  }

  .faqs .faq.open .faq-answer {
    max-height: 1000px;
    opacity: 1;
    margin-top: -30px;
    margin-bottom: -30px;

    padding-left: 10px;
    border-radius: 12px;
    background: rgba(34, 34, 34, 0);
    border: none;
  }

  .faqs .faq.open .faq-answer p {
    font-size: 18px;
    max-height: 1000px;
    opacity: 1;
    padding: 10px;
    color: white;
    font-size: 16px;
    font-family: "Avenir Next";

    padding: 20px;

    background-color: transparent;
    border: none;
    box-shadow: none;
  }

  /* Header */

  .headerBG {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background-color: transparent;
    z-index: 0;
    display: flex;
    height: 0px;
  }

  .mobile-header {
    display: flex;
    justify-content: center;
    background: #111111f2;
    border-radius: 0px;

    position: fixed;
    z-index: 10;
    margin-bottom: 50px;
    border: 1px solid black;
  }
  .mobile-nav-image:hover,
  .mobile-nav-image.active {
    filter: drop-shadow(0 0 15px rgba(209, 175, 72, 0.8));
  }
  .mobile-nav-image {
    display: flex;
    position: relative;
    width: 75px;
    height: auto;
    filter: brightness(0.78);
    transition: filter 0.3s ease;
  }

  /* TYPES */

  .type-description {
    padding: 30px 70px 30px 70px;
    max-width: 800px;
    background: #111111f2;
    background-color: #111111f2;
    font-family: "Noto Sans", sans-serif;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    box-shadow: none;
    font-size: 16px;
    color: white;
    border: 0;
    width: 100%;
  }

  .type-description .descriptionCont {
    margin: 0px;
    padding: 0;
    padding-left: 0;
    padding-right: 0;
    color: white;
    background-color: #111111f2;
    border: 0px;
    box-shadow: none;
  }

  .type-description p {
    margin: 10px 0;
    color: white;
  }

  .TV img {
    filter: brightness(0.5);
  }
  /* Audio Player */

  /* Play/Pause Button */
  .play-button {
    color: white;
  }

  .progress-bar {
    background-color: white;
  }

  .volume-button {
    color: white;
  }

  .volume-slider::-webkit-slider-thumb {
    background: white;
  }

  .volume-slider::-webkit-slider-runnable-track {
    background: white;
  }

  .volume-slider::-moz-range-thumb {
    background: white;
  }

  .volume-slider::-moz-range-track {
    background: white;
  }

  .volume-slider::-ms-thumb {
    background: white;
  }
}
.dark-mode {
  /* MOOOOO-BILE STYLING */
  @media (max-width: 1024px) {
    .faqs {
      margin-top: 130px;
      width: 100%;
      max-width: 80%;
      padding: 0px;
    }
    .mobile-nav-image {
      width: 120px;
      height: auto;
    }
    .headerBG {
      height: 0px;
    }
    .type-description {
      padding: 15px;
      padding-top: 25px;
    }
    .type-description p {
      padding: 5px;
      padding-left: 35px;
      padding-right: 35px;

      font-size: 14px;
    }
    .type-description .descriptionCont {
      padding-left: 10px;
      padding-right: 10px;
    }
    .faqs .faq.open .faq-answer p {
      font-size: 16px;
      max-height: 1000px;
      opacity: 1;
      margin-left: 23px;
      margin-bottom: 5px;
      margin-top: -5px;
      padding: 0px;
    }
    .content {
      background: rgba(34, 34, 34, 0.95);
      font-family: "Avenir Next";

      border: 2px solid #333;
      width: 100%;

      font-family: "Avenir Next";
      background-color: none;
      width: 100%;
      color: white;

      padding: 20px 20px 20px 20px;
    }
    .intro-text {
      background: rgba(34, 34, 34, 0);
      border: none;
      box-shadow: none;
      padding: 15px;
      padding-left: 50px;
      padding-right: 50px;
    }
    .intro-text p {
      color: white;
    }
    ul.introList li::before {
      filter: brightness(0) invert(1);
    }
    .main-layout {
      flex-direction: column;
      background-image: url("./assets/MobileBG.png");
      background-color: #111;
      /* Set the background image */
    }
    .sidebar-left,
    .sidebar-right {
      display: none; /* Hide sidebars on small screens */
    }
    .mobile-header {
      display: flex;
      justify-content: center;
      width: 100%;
    }
    .content-wrapper {
    }
    .TV img {
      max-width: 800px;
      height: auto;
      display: flex;
    }
    .TV {
      margin-top: 120px;
    }
    .coverRow {
      margin-bottom: 30px;
    }
    .intro-text {
      padding: 10px 20px 10px 20px;
    }
    .intro-text p {
      padding: 00px 20px 0px 20px;
    }
    .content-wrapper {
    }
    .TV img {
      max-width: 800px;
      height: auto;
      display: flex;
    }
    .TV {
      margin-top: 120px;
    }
    .coverRow {
      margin-bottom: 30px;
    }
    .intro-text {
      padding: 10px 20px 10px 20px;
    }
    .intro-text p {
      padding: 00px 20px 0px 20px;
    }
    .intro-text h1 {
      padding: 0;
      padding-bottom: 20px;
    }
    .faq-container {
      margin-top: -600px;
    }
    .faqs {
      margin-top: 130px;
      width: 100%;
      max-width: 80%;
      padding: 0px;
    }
    .faqs .faq.open .faq-answer p {
      font-size: 16px;
      max-height: 1000px;
      opacity: 1;
      margin-left: 23px;
      margin-bottom: 20px;
      margin-top: 10px;
      padding: 20px;
    }
  }
  @media only screen and (min-width: 769px) and (max-width: 840px) {
    .intro-text h1 {
      padding: 0;
      padding-bottom: 20px;
    }
    .faqs {
      margin-top: 130px;
      width: 100%;
      max-width: 80%;
      padding: 0px;
    }
    .faqs .faq.open .faq-answer {
      max-height: 1000px;
      opacity: 1;
      margin-top: -10px;
      margin-bottom: -10px;
    }
    .type-description p {
      padding: 0px;
      padding-left: 20px;
      padding-right: 20px;
      font-size: 16px;
    }
    .faq-container {
      margin-top: -400px;
    }
    .coverRow {
      margin-bottom: 30px;
    }
    .intro-text {
      padding: 10px 20px 10px 20px;
    }
    .intro-text p {
      padding: 00px 20px 0px 20px;
    }
    .content {
      width: 100%;
    }
    .content-wrapper {
      width: 100%;
      padding-left: 10px;
      padding-right: 10px;
    }
    .content-wrapper {
      width: 100%;
    }
    .TV img {
      max-width: 730px;
      height: auto;
      display: flex;
    }
    .TV {
      margin-top: 90px;
    }
    .intro-text h1 {
      padding: 0;
      padding-bottom: 20px;
    }
  }
  @media (max-width: 768px) {
    .intro-text h1 {
      padding: 0;
      padding-bottom: 20px;
    }
    .faq-container {
      margin-top: -400px;
    }
    .faqs {
      margin-top: 130px;
      width: 100%;
      max-width: 80%;
      padding: 0px;
    }
    .faqs .faq.open .faq-answer p {
      font-size: 16px;
      max-height: 1000px;
      opacity: 1;
      margin-left: 23px;
      margin-bottom: 20px;
      margin-top: 10px;
      padding: 20px;
    }
    .mobile-nav-image {
      width: 120px;
      height: auto;
    }
    .headerBG {
      height: 128px;
    }
    .type-description {
      padding: 30px;
    }
    .type-description p {
      padding: 0px;
      padding-left: 20px;
      padding-right: 20px;
      font-size: 16px;
    }
    .offensive-table-container,
    .defensive-table-container {
      flex: 1;
      max-width: 50%;
      margin: 2px;
      border-radius: 5px;
    }
    .type-description .descriptionCont {
      padding-left: 10px;
      padding-right: 10px;
    }
    .TV img {
      max-width: 730px;
      height: auto;
    }
    .TV {
      margin-top: 90px;
    }
    .coverRow {
      margin-bottom: 30px;
    }
    .intro-text {
      padding: 10px 20px 10px 20px;
    }
    .intro-text p {
      padding: 00px 20px 0px 20px;
    }
    .content-wrapper {
      width: 100%;
    }
    .video-section {
      position: absolute;
      top: 22.2%; /* Fit into TV */
      left: 15.73%; /* Fit into TV */
      width: 68%; /* Video area */
      height: 40.5%; /* Video area */
      justify-content: center;
    }

    .video-section iframe,
    .video-section video {
      width: 100%;
      height: 100%;
      object-fit: cover;
      pointer-events: auto;
      border: none;
      justify-content: center;
    }
  }

  @media (max-width: 480px) {
    .mobile-nav-image {
      width: 80px;
      height: auto;
    }
    .mobile-nav-image:hover,
    .mobile-nav-image.active {
      filter: drop-shadow(
        0 0 15px rgba(209, 175, 72, 0.8)
      ); /* Light glow effect */
    }
    .headerBG {
      height: 88px;
    }
    .mobile-nav-image {
      width: 100px;
      height: auto;
    }
    .faqs .faq.open .faq-answer p {
      font-size: 14px;
      max-height: 1000px;
      opacity: 1;
      margin-left: 10px;
      margin-bottom: -5px;
      margin-top: -5px;
      padding: 0px;
    }
    .content-wrapper {
      width: 100%;
      padding-left: 10px;
      padding-right: 10px;
    }
    .coverRow {
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      margin-top: 20px;
      margin-bottom: 30px;
    }

    .gba-wrapper {
      position: relative;
      display: inline-block;
      width: 100px;
      height: 100px;
    }
    .intro-text {
      padding: 5px 5px 5px 5px;
    }
    .intro-text p {
      padding: 00px 10px 0px 10px;
    }
    .TV img {
      max-width: 400px;
      height: auto;
      display: flex;
    }
    .TV {
      margin-top: 50px;
    }
  }

  @media (min-width: 412px) and (max-width: 430px) {
    .faqs {
      margin-top: 90px;
      width: 100%;
      max-width: 100%;
      padding: 0px;
    }
    .faqs .faq .faq-question {
      position: relative;

      font-size: 18px;
      padding: 10px;
      padding-bottom: 15px;
      padding-right: 15px;
    }
    .faqs .faq.open .faq-answer p {
      font-size: 14px;
      max-height: 1000px;
      opacity: 1;
      margin-left: 10px;
      margin-bottom: -5px;
      margin-top: -5px;
      padding: 0px;
    }
    .faqs .faq.open .faq-answer p {
      font-size: 16px;
      max-height: 1000px;
      opacity: 1;
      margin-left: -5px;
      margin-bottom: 20px;
      margin-top: 10px;
      padding: 20px;
    }
    .mobile-nav-image {
      width: 85px;
      height: auto;
    }
    .type-description {
      padding: 5px;
    }
    .type-description p {
      padding: 5px;
      padding-left: 20px;
      padding-right: 20px;

      font-size: 14px;
    }
    .type-description .descriptionCont {
      padding-left: 10px;
      padding-right: 10px;
    }
    .mobile-nav-image:hover,
    .mobile-nav-image.active {
      filter: drop-shadow(0 0 15px rgba(209, 175, 72, 0.8));
    }
    .content-wrapper {
      width: 100%;
    }
    .coverRow {
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      margin-top: 20px;
      margin-bottom: 30px;
    }

    .gba-wrapper {
      position: relative;
      display: inline-block;
      width: 100px;
      height: 100px;
    }
    .intro-text {
      padding: 5px 5px 5px 5px;
    }
    .intro-text p {
      padding: 00px 10px 0px 10px;
    }
    .TV img {
      max-width: 380px;
      height: auto;
      display: flex;
    }
    .TV {
      margin-top: 50px;
    }
    .video-section {
      position: absolute;
      top: 22%; /* Fit into TV */
      left: 13%; /* Fit into TV */
      width: 73%; /* Video area */
      height: 40.5%; /* Video area */
      justify-content: center;
    }
    .intro-text h1 {
      font-size: 70px;
      padding: 0;
      padding-bottom: 20px;
    }
  }

  @media (min-width: 390px) and (max-width: 400px) {
    .faqs {
      margin-top: 50px;
      width: 100%;
      max-width: 100%;
      padding: 0px;
    }
    .faqs .faq .faq-question {
      position: relative;

      font-size: 18px;
      padding: 10px;
      padding-bottom: 15px;
      padding-right: 25px;
    }
    .faqs .faq.open .faq-answer p {
      font-size: 14px;
      max-height: 1000px;
      opacity: 1;
      box-shadow: none;
      padding: 0px;
      margin-left: 10px;
      margin-bottom: -5px;
      margin-top: -5px;
    }
    .mobile-nav-image {
      width: 80px;
      height: auto;
    }
    .mobile-nav-image:hover,
    .mobile-nav-image.active {
      filter: drop-shadow(
        0 0 15px rgba(209, 175, 72, 0.8)
      ); /* Light glow effect */
    }
    .headerBG {
      height: 0px;
    }
    .type-description {
      padding: 5px;
    }
    .type-description p {
      padding: 5px;
      padding-left: 15px;
      padding-right: 15px;

      font-size: 14px;
    }
    .type-description .descriptionCont {
      padding-left: 10px;
      padding-right: 10px;
    }
    .offensive-table-container,
    .defensive-table-container {
      flex: 1;
      max-width: 50%;
      margin: 2px;
      border-radius: 5px;
    }
    .mobile-nav-image:hover,
    .mobile-nav-image.active {
      filter: drop-shadow(0 0 15px rgba(209, 175, 72, 0.8));
    }
    .content-wrapper {
      padding-left: 10px;
      padding-right: 10px;
    }
    .coverRow {
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      margin-top: 20px;
      margin-bottom: 30px;
    }

    .gba-wrapper {
      position: relative;
      display: inline-block;
      width: 100px;
      height: 100px;
    }
    .main-layout {
      flex-direction: column;
      background-image: url("./assets/MobileBG.png");
      background-color: #111;
      /* Set the background image */
    }
    .sidebar-left,
    .sidebar-right {
      display: none; /* Hide sidebars on small screens */
    }
    .mobile-header {
      display: flex;
      justify-content: center;
      width: 100%;
    }

    .TV img {
      max-width: 330px;
      height: auto;
      display: flex;
    }
    .TV {
      margin-top: 50px;
    }

    .content-wrapper {
      width: 100%;
      padding-left: 10px;
      padding-right: 10px;
    }
    .coverRow {
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      margin-top: 20px;
      margin-bottom: 30px;
    }

    .gba-wrapper {
      position: relative;
      display: inline-block;
      width: 100px;
      height: 100px;
    }
    .intro-text {
      padding: 5px 5px 5px 5px;
    }
    .intro-text p {
      padding: 00px 10px 0px 10px;
    }
    .intro-text h1 {
      font-size: 50px;
      padding: 0;
      padding-bottom: 20px;
    }
    .faqs .faq .faq-question {
      position: relative;

      font-size: 18px;
      padding: 10px;
      padding-bottom: 15px;
      padding-right: 25px;
    }
    .faq-container {
      margin-top: -200px;
    }
    .faqs {
      margin-top: 130px;
      width: 100%;
      max-width: 80%;
      padding: 0px;
    }
    .faqs .faq.open .faq-answer p {
      font-size: 16px;
      max-height: 1000px;
      opacity: 1;
      margin-left: -5px;
      margin-bottom: 20px;
      margin-top: 10px;
      padding: 20px;
    }
  }

  @media (max-width: 390px) {
    .faqs {
      margin-top: 50px;
      width: 100%;
      max-width: 100%;
      padding: 0px;
    }
    .faqs .faq .faq-question {
      position: relative;

      font-size: 18px;
      padding: 10px;
      padding-bottom: 15px;
      padding-right: 25px;
    }
    .faqs .faq.open .faq-answer p {
      font-size: 14px;
      max-height: 1000px;
      opacity: 1;
      box-shadow: none;
      padding: 0px;
      margin-left: 10px;
      margin-bottom: -5px;
      margin-top: -5px;
    }
    .mobile-nav-image {
      width: 80px;
      height: auto;
    }
    .mobile-nav-image:hover,
    .mobile-nav-image.active {
      filter: drop-shadow(
        0 0 15px rgba(209, 175, 72, 0.8)
      ); /* Light glow effect */
    }
    .headerBG {
      height: 0px;
    }
    .type-description {
      padding: 5px;
    }
    .type-description p {
      padding: 5px;
      padding-left: 15px;
      padding-right: 15px;

      font-size: 14px;
    }
    .type-description .descriptionCont {
      padding-left: 10px;
      padding-right: 10px;
    }
    .offensive-table-container,
    .defensive-table-container {
      flex: 1;
      max-width: 50%;
      margin: 2px;
      border-radius: 5px;
    }
    .mobile-nav-image:hover,
    .mobile-nav-image.active {
      filter: drop-shadow(0 0 15px rgba(209, 175, 72, 0.8));
    }
    .content-wrapper {
      padding-left: 10px;
      padding-right: 10px;
    }
    .coverRow {
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      margin-top: 20px;
      margin-bottom: 30px;
    }

    .gba-wrapper {
      position: relative;
      display: inline-block;
      width: 100px;
      height: 100px;
    }
    .main-layout {
      flex-direction: column;
      background-image: url("./assets/MobileBG.png");
      background-color: #111;
    }
    .sidebar-left,
    .sidebar-right {
      display: none;
    }
    .mobile-header {
      display: flex;
      justify-content: center;
      width: 100%;
    }

    .TV img {
      max-width: 330px;
      height: auto;
      display: flex;
    }
    .TV {
      margin-top: 50px;
    }

    .content-wrapper {
      width: 100%;
      padding-left: 10px;
      padding-right: 10px;
    }
    .coverRow {
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      margin-top: 20px;
      margin-bottom: 30px;
    }

    .gba-wrapper {
      position: relative;
      display: inline-block;
      width: 100px;
      height: 100px;
    }
    .intro-text {
      padding: 5px 5px 5px 5px;
    }
    .intro-text p {
      padding: 00px 10px 0px 10px;
    }
    .intro-text h1 {
      font-size: 50px;
      padding: 0;
      padding-bottom: 20px;
    }
    .faqs .faq .faq-question {
      position: relative;

      font-size: 18px;
      padding: 10px;
      padding-bottom: 15px;
      padding-right: 25px;
    }
    .faq-container {
      margin-top: -200px;
    }
    .faqs {
      margin-top: 130px;
      width: 100%;
      max-width: 80%;
      padding: 0px;
    }
    .faqs .faq.open .faq-answer p {
      font-size: 16px;
      max-height: 1000px;
      opacity: 1;
      margin-left: -5px;
      margin-bottom: 20px;
      margin-top: 10px;
      padding: 20px;
    }
  }
  @media (max-width: 375px) {
    .faqs {
      margin-top: 50px;
      width: 100%;
      max-width: 100%;
      padding: 0px;
    }

    .faqs .faq.open .faq-answer p {
      font-size: 16px;
      max-height: 1000px;
      opacity: 1;
      margin-left: 23px;
      margin-bottom: 20px;
      margin-top: 10px;
      padding: 20px;
    }
    .faqs .faq.open .faq-answer p {
      font-size: 14px;
      max-height: 1000px;
      opacity: 1;
      box-shadow: none;
      padding: 0px;
    }
    .mobile-nav-image {
      width: 70px;
      height: auto;
    }
    .mobile-nav-image:hover,
    .mobile-nav-image.active {
      filter: drop-shadow(
        0 0 15px rgba(209, 175, 72, 0.8)
      ); /* Light glow effect */
    }
    .headerBG {
      height: 0px;
    }
    .type-description {
      padding: 5px;
    }
    .type-description p {
      padding: 5px;
      padding-left: 15px;
      padding-right: 15px;

      font-size: 14px;
    }
    .type-description .type-description-container {
      padding-left: -10px;
      padding-right: 20px;
    }
    .type-rows {
      margin-top: 10px;
      width: 114%;
    }
    .type-image {
      max-width: 34px;
      margin: 0px;
    }
    .offensive-table-container,
    .defensive-table-container {
      flex: 1;
      max-width: 50%;
      margin: 0px;
      border-radius: 5px;
    }
    .mobile-nav-image:hover,
    .mobile-nav-image.active {
      filter: drop-shadow(0 0 15px rgba(209, 175, 72, 0.8));
    }
    .intro-text p {
      padding: 0px 10px 0px 10px;
    }
    .intro-text h1 {
      font-size: 40px;
    }
  }
  @media (min-width: 340px) and (max-width: 360px) {
    .TV {
    }
    .TV img {
      max-width: 300px;
      height: auto;
      display: flex;
    }
  }
}
/* ******************************** */
/* ******************************** */
/* ******************************** */
/* ******************************** */
/* ******************************** */
/* ******************************** */
/* ******************************** */
/* ******************************** */
/* ******************************** */
/* ******************************** */
/* ******************************** */

/* Retro Theme Overrides */
.retro-mode {
  --scrollbar-thumb-color: #7f9c68;
  --scrollbar-track-color: #000;

  .body {
  }
  .main-layout {
    background-image: url("./assets/RetroBG.avif") !important;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

    background-attachment: fixed;
  }

  .sidebar-right::-webkit-scrollbar {
    width: 6px;
  }
  .sidebar-right::-webkit-scrollbar-track {
    background: transparent;
  }
  .sidebar-right::-webkit-scrollbar-thumb {
    background-color: #333;
    border-radius: 4px;
  }

  .background-video {
    display: none !important;
  }
  .mobile-background {
    position: fixed;

    background-image: url("./assets/RetroBG.avif") !important;
  }
  /* Intro */

  .intro-text h1 {
    padding: 10px;
    text-align: center;
    border-radius: 15px;
    font-family: "Born2bSporty FS";
    font-weight: bold;
    color: #71855e !important;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: black;
    text-shadow: #1d2e1f 1px 1px, #1d2e1f 0px 0px, #1d2e1f 1px 1px,
      #1d2e1f 2px 2px, #1d2e1f 3px 3px, #1d2e1f 4px 4px, #1d2e1f 5px 5px,
      #1d2e1f 6px 6px, #1d2e1f 7px 7px, #1d2e1f 8px 8px, #1d2e1f 9px 9px !important;
    font-size: 89px;
    text-decoration: none;
    margin-bottom: 2vw;
    margin-top: 1vw;
    text-shadow: none;
  }
  .GBA {
    filter: grayscale(100%) sepia(70%) hue-rotate(60deg) brightness(75%);
  }

  .content {
    background: #7f9c68 !important;
    font-family: "Noto Sans", sans-serif;
    box-shadow: none;
    border: 1px solid black !important;
    box-shadow: inset 5px 5px 5px 0 rgba(13, 7, 7, 0.8);
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    width: 100%;
    background-color: none;
    width: 100%;
    color: black !important;

    padding: 20px 20px 20px 20px;
  }
  .TV img {
    filter: grayscale(100%) sepia(100%) hue-rotate(60deg) brightness(65%);
  }
  .intro-text {
    background: rgba(34, 34, 34, 0);
    border: none;
    box-shadow: none;
    padding: 15px;
    padding-left: 50px;

    padding-right: 50px;
  }
  .intro-text p,
  .intro-text a {
    color: black !important;
    font-family: "Pokemon Fire Red";
    font-size: 24px;
  }
  ul.introList li::before {
    filter: brightness(0) invert(0) !important;
  }
  .intro-text h1 {
    padding: 0;
    padding-bottom: 20px;
  }
  /* Group Container */

  .group-container,
  .group-container-mobile {
    background: #7f9c68 !important;
    box-shadow: inset 5px 5px 5px 0 rgba(13, 7, 7, 0.8);

    border: 1px solid black;
  }
  /* FAQs */
  .faqs .faq {
    background: #7f9c68;
    box-shadow: inset 5px 5px 5px 0 rgba(13, 7, 7, 0.8);

    border: 1px solid black;
  }

  .faqs .faq .faq-question {
    font-size: 24px;

    background: #21241e;
    border: 1px solid black;
    border-radius: 4px;
    padding: 20px;
    box-shadow: none;
    font-family: "Pokemon Fire Red";

    color: #7f9c68;
  }

  .faqs .faq .faq-answer {
    border: none;
    background: #7f9c68;

    border-radius: 0;
    margin-left: -10px;
  }

  .faqs .faq.open .faq-question {
    margin-bottom: 15px;
    font-family: "Born2bSporty FS";
  }

  .faqs .faq.open .faq-question::after {
    content: "-";
  }

  .faqs .faq.open .faq-answer {
    max-height: 1000px;
    opacity: 1;
    margin-top: -30px;
    margin-bottom: -30px;

    padding-left: 10px;
    border-radius: 12px;
    background: rgba(127, 156, 104, 0);
    border: none;
  }

  .faqs .faq.open .faq-answer p {
    font-size: 24px;
    max-height: 1000px;
    opacity: 1;
    color: black;
    font-size: 24px;
    font-family: "Pokemon Fire Red";
    margin-top: 0px;
    margin-bottom: 0px;
    background-color: transparent;
    border: none;
    box-shadow: none;
  }

  /* Header */

  .sidebar-left {
    width: 250px;
    height: 300px;
    border-radius: 12px;
    margin-left: 10px;
    margin-top: 10px;
    margin-bottom: 10px;

    max-height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    transition: transform 0.5s ease-in-out;
    background-color: #7f9c68 !important;
    background-image: linear-gradient(#7f9c68 50%, transparent 50%) !important;
    border: 1px solid black !important;
    box-shadow: inset 4px 4px 4px 0 rgba(13, 7, 7, 0.8) !important;
    display: flex;
    flex-direction: column;
    z-index: 1000;
    overflow-y: auto;
    box-sizing: border-box;
  }

  .nav-list {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    list-style: none;
    padding: 0;
    margin: 0;
    perspective: 0;
  }

  .nav-item img {
    display: none;
  }

  .nav {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    top: 0;
  }

  .nav-list {
    position: relative;
    top: 0;
  }
  .nav-item,
  .nav-button {
    top: 0;
    display: block;
    width: 200px !important;
    height: 50px !important;
    background-color: #24291d;
    color: #7f9c68;
    border: none;
    cursor: pointer;
    font-size: 16px;
    border: 1px solid black;
    top: auto !important;
    left: auto !important;
    transform: none !important;
    position: relative !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    margin: 1px 0;
    font-family: "Pokemon Fire Red";
    font-size: 34px;
    text-transform: uppercase;
    font-weight: bold;
  }
  .nav-button {
    position: relative;
  }
  .nav-item a {
    text-decoration: none;
  }
  /* Triangle on active button by default */
  .nav-button.active:before {
    content: "";
    position: absolute;
    top: 50%;
    left: -15px;
    width: 0;
    height: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-left: 10px solid #24291d;
    transform: translateY(-50%);
  }

  /* Triangle on hover, overriding the active button */
  .nav-button:hover:before {
    content: "";
    position: absolute;
    top: 50%;
    left: -10px;
    width: 0;
    height: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-left: 10px solid #181010;
    transform: translateY(-50%);
    z-index: 1;
  }

  .nav-item:hover .nav-button.active:before {
    display: none;
  }

  .headerBG {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background-color: transparent;
    z-index: 0;
    display: flex;
    height: 0px;
  }

  .mobile-header {
    display: flex;
    justify-content: center;
    background-color: #7f9c68 !important;

    display: flex;
    justify-content: center;
    background-image: none !important;
    background-size: 100% 2px;
    background-position: 0 0, 0 8px;
    position: fixed;
    z-index: 10;
    margin-bottom: 50px;
    border: 4px solid #1e5f57;
    border-radius: 0px !important;

    border: 1px solid black;
  }
  .mobile-nav-image:hover,
  .mobile-nav-image.active {
    filter: grayscale(80%) sepia(80%) hue-rotate(60deg) brightness(100%) !important;
  }
  .mobile-nav-image {
    display: flex;
    position: relative;
    width: 75px;
    height: auto;
    transition: filter 0.3s ease;

    filter: grayscale(80%) sepia(80%) hue-rotate(60deg) brightness(50%) !important;
  }
  /* Contact Form */
  .contact-form-wrapper {
    margin-top: -150px;
    filter: grayscale(100%) sepia(70%) hue-rotate(60deg) brightness(95%);
    background-color: rgba(127, 156, 104, 0.9);
    background-image: rgba(127, 156, 104, 0.9);
    border-radius: 15px;
    box-shadow: inset 3px 3px 3px 0 rgba(13, 7, 7, 0.8);
    border: 1px solid black;
  }

  /*Sidebar Right */

  .sidebar-right {
    /*    filter: grayscale(100%) sepia(70%) hue-rotate(60deg) brightness(95%)
      saturate(90%);*/
    background-color: #7f9c68 !important;
    background-image: linear-gradient(#689766 50%, transparent 50%) !important;
    border: 1px solid black !important;
    box-shadow: inset 4px 4px 4px 0 rgba(13, 7, 7, 0.8) !important;
  }
  .sidebar-right-mobile {
    border: 1px solid black;
  }

  .news-container {
    background-color: #24291d !important;
    border: 1px solid black !important;
    box-shadow: none !important;
  }
  .news-bar {
    background-color: transparent !important;
  }
  .sidebar-right-title {
    padding-top: 5px;
    color: #7f9c68 !important;
  }
  .news-card:hover {
    border-color: #8fa590;
    background-color: #80c07d;
    background-image: linear-gradient(
      to bottom,
      #80c07d 0%,
      #80c07d 2%,
      #648e57 2%,

      #648e57 86%,
      #80c07d 86%,

      #80c07d 88%,
      #648e57 88%,

      #648e57 90%,
      #80c07d 90%,
      #80c07d 100%
    );
    transform: translateY(-5px) !important;
  }
  .news-card-content p,
  h1 {
    color: black !important;
    text-shadow: none !important;
  }
  .news-card {
    border-color: #2a352b;

    background-image: linear-gradient(
      to bottom,
      #566955 0%,
      #566955 2%,
      #82a176 2%,

      #82a176 86%,
      #566955 86%,

      #566955 88%,
      #82a176 88%,

      #82a176 90%,
      #566955 90%,
      #566955 100%
    );
  }
  .news-btn {
    font-family: "Pokemon Fire Red", sans-serif;
    background-color: #2a2c28;
    color: #e0dfd9;
  }
  .news-card img {
    filter: grayscale(1);
  }
  .outer-container {
    filter: grayscale(100%) sepia(70%) hue-rotate(60deg) brightness(95%)
      saturate(90%);
    box-shadow: inset 3px 3px 3px 0 rgba(13, 7, 7, 0.8);
    background-color: #689766;
    padding: 10px;
    border: 1px solid black;
    border-radius: 12px;
    width: 90%;
    max-width: 650px;

    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .modal-header {
    margin: 0;
    margin-top: -10px;
    margin-bottom: -20px;
    font-size: 40px;
    font-family: "Born2bSporty FS", sans-serif;
    font-weight: bold;
    color: #71855e;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: black;

    text-shadow: #1d2e1f 1px 1px, #1d2e1f 0px 0px, #1d2e1f 1px 1px,
      #1d2e1f 2px 2px, #1d2e1f 3px 3px, #1d2e1f 4px 4px, #1d2e1f 5px 5px,
      #1d2e1f 6px 6px, #1d2e1f 7px 7px, #1d2e1f 8px 8px, #1d2e1f 9px 9px;

    text-decoration: none;

    text-align: center;

    flex-shrink: 0;
  }
  .modal-content {
    background: #7f9c68;
    border: 2px solid black;
    box-shadow: none;

    position: relative;
    border-radius: 8px;
    padding: 20px;
    padding-top: 0px;
    overflow: hidden;
    font-family: "Born2bSporty FS", sans-serif;
    display: flex;
    flex-direction: column;

    max-height: 80vh;
    width: 100%;

    box-sizing: border-box;
  }

  .modal-body p {
    text-decoration: none;
    font-family: "Born2bSporty FS", sans-serif;
  }

  /* TYPES */

  .type-description {
    padding: 30px 70px 30px 70px;
    max-width: 800px;
    background: #7f9c68;
    font-family: "Noto Sans", sans-serif;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    box-shadow: none;
    font-size: 16px;
    color: black;
    border: 0;
    width: 100%;
    border: 1px solid black !important;
    box-shadow: inset 5px 0px 5px 0 rgba(13, 7, 7, 0.8);
  }
  .tabs {
    border-left: 1px solid black !important;
    border-right: 1px solid black !important;
    border-top: 1px solid black !important;
    box-shadow: inset 5px 0px 5px 0 rgba(13, 7, 7, 0.8) !important;
    z-index: 2;
    position: relative;
  }
  .tab {
    background: rgb(13, 7, 7, 0.8) !important;
    z-index: -1;
    font-family: "Born2bSporty FS", sans-serif;
    font-weight: bold;
    color: #768b63;
    -webkit-text-stroke-color: black;
    letter-spacing: 0.05em;
    text-shadow: #404d42 1px 1px, #404d42 0px 0px, #404d42 1px 1px;
  }
  .tab.active {
    background: rgb(13, 7, 7, 0.5) !important;
    z-index: -1;
  }
  .type-rows {
    border: 2px solid black !important;
    background: #7f9c68;
    box-shadow: inset 5px 5px 5px 0 rgba(13, 7, 7, 0.8);
  }
  .type-header {
    background: rgb(26, 29, 23) !important;
    border: 1px solid black !important;
    border-bottom: none !important;
    box-shadow: inset 5px 5px 5px 0 rgba(13, 7, 7, 0.8);
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
  }
  .type-header h2 {
    font-family: "Born2bSporty FS", sans-serif;
    font-weight: bold;
    color: #768b63;
    -webkit-text-stroke-color: black;

    text-shadow: #404d42 1px 1px, #404d42 0px 0px, #404d42 1px 1px,
      #404d42 2px 2px, #404d42 3px 3px, #404d42 4px 4px, #404d42 5px 5px;
  }

  .type-image {
    filter: grayscale(100%) sepia(70%) hue-rotate(60deg) brightness(100%)
      contrast(95%);
  }
  .type-description-container {
    background: rgba(127, 156, 104, 1) !important;
    border-radius: 20px;
  }
  .type-description .descriptionCont {
    margin: 0px;
    padding: 0;
    padding-left: 0;
    padding-right: 0;
    color: black;
    background-color: rgba(34, 34, 34, 0);
    border: 0px;
    box-shadow: none;
  }
  .type-description table {
  }
  .table thead th {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }
  .table tbody tr:last-child td:first-child {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }
  .offensive-table-container,
  .defensive-table-container {
    filter: grayscale(100%) sepia(70%) hue-rotate(60deg) brightness(85%);
  }
  .offensive-table-container tr,
  .defensive-table-container tr {
    filter: grayscale(100%) sepia(70%) hue-rotate(60deg) brightness(85%);
    border-top-right-radius: 2px;
    color: black !important;
  }
  .offensive-table-container th,
  .defensive-table-container th {
    filter: grayscale(100%) sepia(70%) hue-rotate(60deg) brightness(85%);
    border-top-right-radius: 2px;
  }
  .offensive-table-container td,
  .offensive-table-container a,
  .defensive-table-container td,
  .defensive-table-container a {
    background-color: #7ea762 !important;
    color: black !important;
    text-shadow: none;
  }
  .type-description p,
  .type-description span {
    font-size: 24px;
    margin: 10px 0;
    color: black !important;
    font-family: "Pokemon Fire Red";
  }

  .mobile-header {
    box-shadow: inset 4px 4px 5px 0 rgba(13, 7, 7, 0.8) !important;
  }
  .sidebar-right-toggle {
    background: rgb(74, 94, 58) !important;
    border: 1px solid black;
  }

  /* MOOOOO-BILE STYLING */
  @media (max-width: 1024px) {
    .faqs {
      margin-top: 130px;
      width: 100%;
      max-width: 80%;
      padding: 0px;
    }
    .mobile-nav-image {
      width: 120px;
      height: auto;
    }
    .headerBG {
      height: 0px;
    }
    .type-description {
      padding: 15px;
      padding-top: 25px;
    }
    .type-description p {
      padding: 5px;
      padding-left: 35px;
      padding-right: 35px;

      font-size: 14px;
    }
    .type-description .descriptionCont {
      padding-left: 10px;
      padding-right: 10px;
    }
    .faqs .faq.open .faq-answer p {
      font-size: 16px;
      max-height: 1000px;
      opacity: 1;
      margin-left: 23px;
      margin-bottom: 5px;
      margin-top: -5px;
      padding: 0px;
    }
    .content {
      background: rgba(34, 34, 34, 0.9);
      font-family: "Avenir Next";

      border: 2px solid white;
      width: 100%;

      font-family: "Avenir Next";
      background-color: none;
      width: 100%;
      color: white;

      padding: 20px 20px 20px 20px;
    }
    .intro-text {
      background: rgba(34, 34, 34, 0);
      border: none;
      box-shadow: none;
      padding: 15px;
      padding-left: 50px;
      padding-right: 50px;
    }
    .intro-text p {
      color: white;
    }
    ul.introList li::before {
      filter: brightness(0) invert(1);
    }
    .main-layout {
      flex-direction: column;
      background-image: url("./assets/MobileBG.png");
      background-color: #111;
    }
    .sidebar-left,
    .sidebar-right {
      display: none;
    }
    .mobile-header {
      display: flex;
      justify-content: center;
      width: 100%;
    }
    .content-wrapper {
    }
    .TV img {
      max-width: 800px;
      height: auto;
      display: flex;
    }
    .TV {
      margin-top: 120px;
    }
    .coverRow {
      margin-bottom: 30px;
    }
    .intro-text {
      padding: 10px 20px 10px 20px;
    }
    .intro-text p {
      padding: 00px 20px 0px 20px;
    }
    .content-wrapper {
    }
    .TV img {
      max-width: 800px;
      height: auto;
      display: flex;
    }
    .TV {
      margin-top: 120px;
    }
    .coverRow {
      margin-bottom: 30px;
    }
    .intro-text {
      padding: 10px 20px 10px 20px;
    }
    .intro-text p {
      padding: 00px 20px 0px 20px;
    }
    .intro-text h1 {
      padding: 0;
      padding-bottom: 20px;
    }
    .faq-container {
      margin-top: -600px;
    }
    .faqs {
      margin-top: 130px;
      width: 100%;
      max-width: 80%;
      padding: 0px;
    }
    .faqs .faq.open .faq-answer p {
      font-size: 16px;
      max-height: 1000px;
      opacity: 1;
      margin-left: 23px;
      margin-bottom: 20px;
      margin-top: 10px;
      padding: 20px;
    }
  }
  @media only screen and (min-width: 769px) and (max-width: 840px) {
    .intro-text h1 {
      padding: 0;
      padding-bottom: 20px;
    }
    .faqs {
      margin-top: 130px;
      width: 100%;
      max-width: 80%;
      padding: 0px;
    }
    .faqs .faq.open .faq-answer {
      max-height: 1000px;
      opacity: 1;
      margin-top: -10px;
      margin-bottom: -10px;
    }
    .type-description p {
      padding: 0px;
      padding-left: 20px;
      padding-right: 20px;
      font-size: 26px;
    }
    .faq-container {
      margin-top: -400px;
    }
    .coverRow {
      margin-bottom: 0px;
    }
    .intro-text {
      padding: 10px 20px 10px 20px;
    }
    .intro-text p {
      padding: 00px 20px 0px 20px;
      font-size: 26px;
    }
    .content {
      width: 100%;
    }
    .content-wrapper {
      width: 100%;
      padding-left: 10px;
      padding-right: 10px;
    }
    .content-wrapper {
      width: 100%;
    }
    .TV img {
      max-width: 730px;
      height: auto;
      display: flex;
    }
    .TV {
      margin-top: 90px;
    }
    .intro-text h1 {
      padding: 0;
      padding-bottom: 20px;
    }
  }
  @media (max-width: 768px) {
    .intro-text h1 {
      padding: 0;
      padding-bottom: 20px;
    }
    .faq-container {
      margin-top: -400px;
    }
    .faqs {
      margin-top: 130px;
      width: 100%;
      max-width: 80%;
      padding: 0px;
    }
    .faqs .faq.open .faq-answer p {
      font-size: 16px;
      max-height: 1000px;
      opacity: 1;
      margin-left: 23px;
      margin-bottom: 20px;
      margin-top: 10px;
      padding: 20px;
    }
    .mobile-nav-image {
      width: 120px;
      height: auto;
    }
    .headerBG {
      height: 128px;
    }
    .type-description {
      padding: 30px;
    }
    .type-description p {
      padding: 0px;
      padding-left: 20px;
      padding-right: 20px;
      font-size: 16px;
    }
    .offensive-table-container,
    .defensive-table-container {
      flex: 1;
      max-width: 50%;
      margin: 2px;
      border-radius: 5px;
    }
    .type-description .descriptionCont {
      padding-left: 10px;
      padding-right: 10px;
    }
    .TV img {
      max-width: 730px;
      height: auto;
    }
    .TV {
      margin-top: 00px;
    }
    .coverRow {
      margin-bottom: 20px;
    }
    .intro-text {
      padding: 10px 20px 10px 20px;
    }
    .intro-text p {
      padding: 00px 20px 0px 20px;
      font-size: 22px;
    }
    .content-wrapper {
      width: 100%;
    }
    .video-section {
      position: absolute;
      top: 22.2%; /* Fit into TV */
      left: 15.73%; /* Fit into TV */
      width: 68%; /* Video area */
      height: 40.5%; /* Video area */
      justify-content: center;
    }

    .video-section iframe,
    .video-section video {
      width: 100%;
      height: 100%;
      object-fit: cover;
      pointer-events: auto;
      border: none;
      justify-content: center;
    }
    .contact-form-wrapper {
      margin-top: -300px;
    }
  }

  @media (max-width: 480px) {
    .mobile-nav-image {
      width: 80px;
      height: auto;
    }
    .mobile-nav-image:hover,
    .mobile-nav-image.active {
      filter: drop-shadow(0 0 15px rgba(209, 175, 72, 0.8));
    }
    .headerBG {
      height: 88px;
    }
    .mobile-nav-image {
      width: 100px;
      height: auto;
    }
    .faqs .faq.open .faq-answer p {
      font-size: 14px;
      max-height: 1000px;
      opacity: 1;
      margin-left: 10px;
      margin-bottom: -5px;
      margin-top: -5px;
      padding: 0px;
    }
    .content-wrapper {
      width: 100%;
      padding-left: 10px;
      padding-right: 10px;
    }
    .coverRow {
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      margin-top: 20px;
      margin-bottom: 30px;
    }

    .gba-wrapper {
      position: relative;
      display: inline-block;
      width: 100px;
      height: 100px;
    }
    .intro-text {
      padding: 5px 5px 5px 5px;
    }
    .intro-text p {
      padding: 00px 10px 0px 10px;
    }
    .TV img {
      max-width: 400px;
      height: auto;
      display: flex;
    }
    .TV {
      margin-top: 50px;
    }
    .contact-form-wrapper {
      margin-top: 0px;
    }
  }

  @media (min-width: 412px) and (max-width: 430px) {
    .faqs {
      margin-top: 90px;
      width: 100%;
      max-width: 100%;
      padding: 0px;
    }
    .faqs .faq .faq-question {
      position: relative;

      font-size: 18px;
      padding: 10px;
      padding-bottom: 15px;
      padding-right: 15px;
    }
    .faqs .faq.open .faq-answer p {
      font-size: 14px;
      max-height: 1000px;
      opacity: 1;
      margin-left: 10px;
      margin-bottom: -5px;
      margin-top: -5px;
      padding: 0px;
    }
    .faqs .faq.open .faq-answer p {
      font-size: 16px;
      max-height: 1000px;
      opacity: 1;
      margin-left: -5px;
      margin-bottom: 20px;
      margin-top: 10px;
      padding: 20px;
    }
    .mobile-nav-image {
      width: 85px;
      height: auto;
    }
    .type-description {
      padding: 5px;
    }
    .type-description p {
      padding: 5px;
      padding-left: 20px;
      padding-right: 20px;

      font-size: 18px;
    }
    .type-description .descriptionCont {
      padding-left: 10px;
      padding-right: 10px;
    }
    .mobile-nav-image:hover,
    .mobile-nav-image.active {
      filter: grayscale(1);
    }
    .content-wrapper {
      width: 100%;
    }
    .coverRow {
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      margin-top: 10px;
      margin-bottom: -20px;
    }

    .gba-wrapper {
      position: relative;
      display: inline-block;
      width: 100px;
      height: 100px;
    }
    .intro-text {
      padding: 5px 5px 5px 5px;
    }
    .intro-text p {
      padding: 00px 10px 0px 10px;
      font-size: 18px;
    }
    .TV img {
      max-width: 380px;
      height: auto;
      display: flex;
    }
    .TV {
      margin-top: 50px;
    }
    .video-section {
      position: absolute;
      top: 22%; /* Fit into TV */
      left: 13%; /* Fit into TV */
      width: 73%; /* Video area */
      height: 40.5%; /* Video area */
      justify-content: center;
    }
    .intro-text h1 {
      font-size: 70px;
      padding: 0;
      padding-bottom: 20px;
    }
    .contact-form-wrapper {
      margin-top: 0px;
    }
  }

  @media (min-width: 390px) and (max-width: 400px) {
    .faqs {
      margin-top: 50px;
      width: 100%;
      max-width: 100%;
      padding: 0px;
    }
    .faqs .faq .faq-question {
      position: relative;

      font-size: 18px;
      padding: 10px;
      padding-bottom: 15px;
      padding-right: 25px;
    }
    .faqs .faq.open .faq-answer p {
      font-size: 14px;
      max-height: 1000px;
      opacity: 1;
      box-shadow: none;
      padding: 0px;
      margin-left: 10px;
      margin-bottom: -5px;
      margin-top: -5px;
    }
    .mobile-nav-image {
      width: 80px;
      height: auto;
    }
    .mobile-nav-image:hover,
    .mobile-nav-image.active {
      filter: grayscale(100%) sepia(70%) hue-rotate(60deg) brightness(100%);
    }
    .headerBG {
      height: 0px;
    }
    .type-description {
      padding: 5px;
    }
    .type-description p {
      padding: 5px;
      padding-left: 15px;
      padding-right: 15px;

      font-size: 18px;
    }
    .type-description .descriptionCont {
      padding-left: 10px;
      padding-right: 10px;
    }
    .offensive-table-container,
    .defensive-table-container {
      flex: 1;
      max-width: 50%;
      margin: 2px;
      border-radius: 5px;
    }
    .mobile-nav-image:hover,
    .mobile-nav-image.active {
      filter: grayscale(100%) sepia(70%) hue-rotate(60deg) brightness(100%);
    }
    .content-wrapper {
      padding-left: 10px;
      padding-right: 10px;
    }
    .coverRow {
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      margin-top: 10px;
      margin-bottom: -30px;
    }

    .gba-wrapper {
      position: relative;
      display: inline-block;
      width: 100px;
      height: 100px;
    }
    .main-layout {
      flex-direction: column;
      background-image: url("./assets/MobileBG.png");
      background-color: #111;
    }
    .sidebar-left,
    .sidebar-right {
      display: none;
    }
    .mobile-header {
      display: flex;
      justify-content: center;
      width: 100%;
    }

    .TV img {
      max-width: 330px;
      height: auto;
      display: flex;
    }
    .TV {
      margin-top: 50px;
    }

    .content-wrapper {
      width: 100%;
      padding-left: 10px;
      padding-right: 10px;
    }

    .gba-wrapper {
      position: relative;
      display: inline-block;
      width: 100px;
      height: 100px;
    }
    .intro-text {
      padding: 5px 5px 5px 5px;
    }
    .intro-text p {
      padding: 00px 10px 0px 10px;
    }
    .intro-text h1 {
      font-size: 50px;
      padding: 0;
      padding-bottom: 20px;
    }
    .faqs .faq .faq-question {
      position: relative;

      font-size: 18px;
      padding: 10px;
      padding-bottom: 15px;
      padding-right: 25px;
    }
    .faq-container {
      margin-top: -200px;
    }
    .faqs {
      margin-top: 130px;
      width: 100%;
      max-width: 80%;
      padding: 0px;
    }
    .faqs .faq.open .faq-answer p {
      font-size: 16px;
      max-height: 1000px;
      opacity: 1;
      margin-left: -5px;
      margin-bottom: 20px;
      margin-top: 10px;
      padding: 20px;
    }
    .contact-form-wrapper {
      margin-top: 0px;
    }
  }

  @media (max-width: 390px) {
    .contact-form-wrapper {
      margin-top: 0px;
    }
    .faqs {
      margin-top: 50px;
      width: 100%;
      max-width: 100%;
      padding: 0px;
    }
    .faqs .faq .faq-question {
      position: relative;

      font-size: 18px;
      padding: 10px;
      padding-bottom: 15px;
      padding-right: 25px;
    }
    .faqs .faq.open .faq-answer p {
      font-size: 14px;
      max-height: 1000px;
      opacity: 1;
      box-shadow: none;
      padding: 0px;
      margin-left: 10px;
      margin-bottom: -5px;
      margin-top: -5px;
    }
    .mobile-nav-image {
      width: 80px;
      height: auto;
      filter: grayscale(80%) sepia(80%) hue-rotate(60deg) brightness(50%) !important;
    }

    .headerBG {
      height: 0px;
    }
    .type-description {
      padding: 5px;
    }
    .type-description p {
      padding: 5px;
      padding-left: 15px;
      padding-right: 15px;

      font-size: 17px;
    }
    .type-description .descriptionCont {
      padding-left: 10px;
      padding-right: 10px;
    }
    .offensive-table-container,
    .defensive-table-container {
      flex: 1;
      max-width: 50%;
      margin: 2px;
      border-radius: 5px;
    }
    .mobile-nav-image:hover,
    .mobile-nav-image.active {
      filter: grayscale(80%) sepia(80%) hue-rotate(60deg) brightness(110%) !important;
    }
    .content-wrapper {
      padding-left: 10px;
      padding-right: 10px;
    }
    .coverRow {
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      margin-top: 0px;
      margin-bottom: -10px;
    }

    .gba-wrapper {
      position: relative;
      display: inline-block;
      width: 100px;
      height: 100px;
    }
    .main-layout {
      flex-direction: column;
      background-image: url("./assets/MobileBG.png");
      background-color: #111;
      /* Set the background image */
    }
    .sidebar-left,
    .sidebar-right {
      display: none;
    }
    .mobile-header {
      display: flex;
      justify-content: center;
      width: 100%;
    }

    .TV img {
      max-width: 330px;
      height: auto;
      display: flex;
    }
    .TV {
      margin-top: 50px;
    }

    .content-wrapper {
      width: 100%;
      padding-left: 10px;
      padding-right: 10px;
    }

    .gba-wrapper {
      position: relative;
      display: inline-block;
      width: 100px;
      height: 100px;
    }
    .intro-text {
      padding: 5px 5px 5px 5px;
    }
    .intro-text p {
      padding: 00px 10px 0px 10px;
    }
    .intro-text h1 {
      font-size: 50px;
      padding: 0;
      padding-bottom: 20px;
    }
    .faqs .faq .faq-question {
      position: relative;

      font-size: 18px;
      padding: 10px;
      padding-bottom: 15px;
      padding-right: 25px;
    }
    .faq-container {
      margin-top: -200px;
    }
    .faqs {
      margin-top: 130px;
      width: 100%;
      max-width: 80%;
      padding: 0px;
    }
    .faqs .faq.open .faq-answer p {
      font-size: 16px;
      max-height: 1000px;
      opacity: 1;
      margin-left: -5px;
      margin-bottom: 20px;
      margin-top: 10px !important;
      padding: 20px;
    }
  }
  @media (max-width: 375px) {
    .faqs {
      margin-top: 50px;
      width: 100%;
      max-width: 100%;
      padding: 0px;
    }

    .faqs .faq.open .faq-answer p {
      font-size: 16px;
      max-height: 1000px;
      opacity: 1;
      margin-left: 23px;
      margin-bottom: 20px;
      margin-top: 10px;
      padding: 20px;
    }
    .faqs .faq.open .faq-answer p {
      font-size: 14px;
      max-height: 1000px;
      opacity: 1;
      box-shadow: none;
      padding: 0px;
    }
    .mobile-nav-image {
      width: 70px;
      height: auto;
    }
    .mobile-nav-image:hover,
    .mobile-nav-image.active {
      filter: drop-shadow(0 0 15px rgba(0, 0, 0, 0.8));
      filter: grayscale(1);
    }
    .headerBG {
      height: 0px;
    }
    .type-description {
      padding: 5px;
    }
    .type-description p {
      padding: 5px;
      padding-left: 15px;
      padding-right: 15px;

      font-size: 14px;
    }
    .type-description .type-description-container {
      padding-left: -10px;
      padding-right: 20px;
    }
    .type-rows {
      margin-top: 10px;
      width: 114%;
    }
    .type-image {
      max-width: 34px;
      margin: 0px;
    }
    .offensive-table-container,
    .defensive-table-container {
      flex: 1;
      max-width: 50%;
      margin: 0px;
      border-radius: 5px;
    }
    .mobile-nav-image:hover,
    .mobile-nav-image.active {
      filter: drop-shadow(0 0 15px rgba(0, 0, 0, 0.8));
      filter: grayscale(1);
    }
    .intro-text p {
      padding: 0px 10px 0px 10px;
    }
    .intro-text h1 {
      font-size: 40px;
    }
    .contact-form-wrapper {
      margin-top: 0px;
    }
  }
  @media (min-width: 340px) and (max-width: 360px) {
    .TV {
    }
    .TV img {
      max-width: 300px;
      height: auto;
      display: flex;
    }
  }
}

/* Apply Variables */
body {
  background-color: var(--background-color);
  color: var(--text-color);
  transition: background-color 0.3s ease, color 0.3s ease;
}

header {
  background-color: var(--header-background);
  color: var(--header-text-color);
  transition: background-color 0.3s ease, color 0.3s ease;
}
