.group-container-mobile {
  margin-top: 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: rgba(34, 34, 34, 0.85);
  border: 1px solid black;
  margin-bottom: 20px;
  padding: 20px;
  padding-top: 10px;
  border-radius: 15px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.player-column {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}

.title-row {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: 0 auto;
  width: 100%;
}

.title-bar {
  display: flex;
  height: 2em;
  margin: 0 20px;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.type-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 200px;

  border-radius: 8px;
  margin-top: 15px;
  box-sizing: border-box;
}
