.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1004;
  overflow: hidden;
}

.outer-container {
  background-color: #f9d075;
  padding: 10px;
  border: 4px solid #474b4e;
  border-radius: 12px;
  width: 90%;
  max-width: 650px;

  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background-color: #ffffce;
  border: 4px solid #d1af48;
  box-shadow: inset 0px 0px 8px rgba(150, 125, 52, 1);
  position: relative;
  border-radius: 8px;
  padding: 20px;
  padding-top: 0px;
  overflow: hidden;

  display: flex;
  flex-direction: column;

  max-height: 80vh;
  width: 100%;

  box-sizing: border-box;
}

.modal-header {
  margin: 0;
  margin-top: -10px;
  font-size: 40px;
  font-family: "Pokemon Fire Red", sans-serif;
  font-weight: bold;
  color: white;
  text-decoration: none;
  text-shadow: 2px 0px 0px black,
    /* Bottom-right dark shadow */ 0px 2px 0px black,
    /* Directly underneath shadow */ 2px 2px 0px black;
  text-align: center;

  /* Prevent header from shrinking */
  flex-shrink: 0;
}

.modal-body {
  margin-top: 10px;
  padding-top: 10px;
  font-size: 16px;
  font-family: "Avenir Next", sans-serif;

  overflow-y: auto;

  flex-grow: 1;

  padding-right: 10px;
}

.modal-body p {
  text-decoration: underline dashed rgb(229, 221, 153);
  text-decoration-thickness: 3px;
  text-underline-offset: 4px;
  width: 100%;
  margin-top: 0px;
  text-align: justify;
}

.modal-close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: transparent;
  border: none;
  font-size: 24px;
  font-style: bold;
  cursor: pointer;
  color: #333;
}

.modal-close-btn:hover {
  color: red;
}

.dark-mode {
  .outer-container {
    background-color: #111;
    padding: 10px;
    border: 2px solid #333;
    border-radius: 12px;
    width: 90%;
    max-width: 650px;

    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .modal-content {
    background-color: #222;
    border: 2px solid #111;
    box-shadow: inset 0px 0px 8px rgb(20, 20, 20);
    position: relative;
    border-radius: 8px;
    padding: 20px;
    padding-top: 0px;
    overflow: hidden;

    display: flex;
    flex-direction: column;

    max-height: 80vh;
    width: 100%;

    box-sizing: border-box;
  }

  .modal-header {
    margin: 0;
    margin-top: -10px;
    font-size: 40px;
    font-family: "Pokemon Fire Red", sans-serif;
    font-weight: bold;
    color: white;
    text-decoration: none;
    text-shadow: 2px 0px 0px black,
      /* Bottom-right dark shadow */ 0px 2px 0px black,
      /* Directly underneath shadow */ 2px 2px 0px black;
    text-align: center;

    /* Prevent header from shrinking */
    flex-shrink: 0;
  }

  .modal-body {
    margin-top: 10px;
    padding-top: 10px;
    font-size: 16px;
    font-family: "Avenir Next", sans-serif;

    overflow-y: auto;

    flex-grow: 1;

    padding-right: 10px;
  }

  .modal-body p {
    text-decoration: underline dashed rgb(49, 49, 49);
    text-decoration-thickness: 3px;
    text-underline-offset: 4px;
    width: 100%;
    margin-top: 0px;
    text-align: justify;
    color: white;
  }

  .modal-close-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: transparent;
    border: none;
    font-size: 24px;
    font-style: bold;
    cursor: pointer;
    color: #f3f3f3;
  }

  .modal-close-btn:hover {
    color: rgb(138, 198, 233);
  }
}

@media (max-width: 480px) {
  .outer-container {
    width: 95%;
    max-width: 350px;
  }

  .modal-content {
    max-height: 90vh;
  }

  .modal-header {
    font-size: 30px;
  }

  .modal-body {
    font-family: "Pokemon Fire Red", sans-serif;
    font-size: 16px;
    text-align: left;
    padding-top: 0px;
  }

  .modal-body p {
    text-align: justify;
  }
}
