.sidebar-left {
  position: absolute;

  position: fixed;
  top: 15%;
  height: 500px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  z-index: 100;
}

.nav-list {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  list-style: none;
  padding: 0;
  margin: 0;
  perspective: 393px;
}

.nav-image {
  width: 120px;
  height: auto;
  object-fit: contain;
  transform: translate(0, 0);
}

.nav {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.nav-item {
  position: absolute;
}

.nav-item:nth-child(1) {
  top: -98px;
  left: 50px;
  transform: rotate(-2deg);
}

.nav-item:nth-child(2) {
  top: 30px;
  left: 18px;
  transform: rotate(0deg);
}

.nav-item:nth-child(3) {
  top: 150px;
  left: 50px;
  transform: rotate(0deg);
}

.nav-item:nth-child(4) {
  top: 270px;
  left: 19px;
}

.nav-item:nth-child(5) {
  top: 390px;
  left: 35px;
}

@keyframes pulse-blue {
  0% {
    transform: scale(0.95);
    drop-shadow: 0 0 0 0 rgba(134, 217, 250, 0.7);
  }

  70% {
    transform: scale(1);
    drop-shadow: 0 0 0 10px rgba(255, 177, 66, 0);
  }

  100% {
    transform: scale(0.95);
    drop-shadow: 0 0 0 0 rgba(255, 177, 66, 0);
  }
}

.nav-image:hover,
.nav-image.active {
  filter: drop-shadow(0 0 15px rgb(140, 231, 238));
  animation: pulse-blue 2s infinite;
}

.nav-image {
  filter: brightness(0.68);
  transition: filter 0.3s ease;
}

.nav-image.active:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 100px;
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-left: 10px solid #24291d;
  transform: translateY(-50%);
}

.dark-mode {
  .nav-image {
    filter: brightness(0.28);
    transition: filter 0.3s ease;
  }
  .nav-image:hover,
  .nav-image.active {
    filter: brightness(0.7);

    filter: drop-shadow(0 0 15px #8ce7ee);
    animation: pulse-blue 2s infinite;
  }

  .nav-image.active:before {
    content: "";
    position: absolute;
    top: 50%;
    left: 100px;
    width: 0;
    height: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-left: 10px solid #24291d;
    transform: translateY(-50%);
  }
}

/* Triangle on hover, overriding the active button */
img.nav-image:hover:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 100px;
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-left: 10px solid #181010;
  transform: translateY(-50%);
  z-index: 1;
}

/* Ensure only one triangle shows when hovering for retro, this is still broken though idk */
.nav-image:hover .nav-image.active:before {
  display: none;
}

@media (max-width: 1366px) {
  .nav-image {
    width: 90px;
    height: auto;
  }
  .nav-item:nth-child(1) {
    top: -125px;
    left: 30px;
  }
  .nav-item:nth-child(2) {
    top: -23px;
    left: 12px;
    transform: rotate(0deg);
  }

  .nav-item:nth-child(3) {
    top: 70px;
    left: 29px;
    transform: rotate(0deg);
  }

  .nav-item:nth-child(4) {
    top: 160px;
    left: 10px;
  }

  .nav-item:nth-child(5) {
    top: 260px;
    left: 25px;
  }
}
