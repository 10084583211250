@import url("https://fonts.cdnfonts.com/css/born2bsporty-fs");

.contact-form-wrapper {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 20px;
  width: 100%;
  margin: 0 auto;
  padding: 50px 0;
  position: relative;
}

.contact-form-container,
.icon-container {
  max-width: 350px;
  min-height: 550px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 30px;
  border: 6px solid transparent;
  border-radius: 4px;
  box-shadow: inset 0px 0px 5px rgba(0, 0, 0, 0.1);
  background-clip: padding-box, border-box;
  background-image: url("../../assets/PhoneBG.jpg"),
    linear-gradient(180deg, #bbc449 10%, #239063 5%, #239063 90%, #bbc449 10%);
  background-origin: border-box;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  flex-grow: 1;
  box-sizing: border-box;
  position: relative;
}

.contact-form-container::before,
.icon-container::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.02); /* Animation for retro light stuff */
  pointer-events: none;
  opacity: 0.5;
}

/* Contact Form Styles */
#contact-form {
  display: flex;
  flex-direction: column;
  font-family: "Born2bSporty FS", sans-serif;
  color: white;
}

h3 {
  text-align: center;
  margin-bottom: 15px;
  margin-top: 0px;
  font-size: 32px;
  color: white;
  padding-bottom: 10px;
  text-shadow: 3px 3px 0 #000, -1px -1px 0 #000, 1px -1px 0 #000,
    -1px 1px 0 #000, 1px 1px 0 #000;
  letter-spacing: 0.01em;
}

.input-container {
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  border: 1px solid #4d97b4;
  padding: 5px;
  border-radius: 5px;
  background-color: rgba(143, 255, 245, 0.2);
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.3);
}

.input-container label {
  display: block;
  font-size: 20px;
  margin-bottom: 5px;
  color: white;
  text-shadow: 3px 3px 0 #000, -1px -1px 0 #000, 1px -1px 0 #000,
    -1px 1px 0 #000, 1px 1px 0 #000;
  letter-spacing: 0.01em;
}

.input-container input,
.input-container textarea {
  padding: 8px;
  font-size: 14px;
  border-radius: 5px;
  border: 1px solid #44c49b;
  background-color: #4d97b4;
  color: #fff;
  box-sizing: border-box;
  transition: border-color 0.3s ease, background-color 0.3s ease;
}

.input-container input:focus,
.input-container textarea:focus {
  border: 2px solid #bbc449;
  background-color: #4d97b4;
  outline: none;
}

#submit-btn {
  margin: auto;
  background-color: #15698a;
  color: #fff;
  border: 0px solid #4d97b4;
  padding: 10px;
  padding-top: 8px;
  padding-left: 30px;
  padding-right: 30px;
  cursor: pointer;
  font-size: 24px;
  border-radius: 5px;
  text-align: center;
  text-transform: uppercase;
  font-family: "Born2bSporty FS", sans-serif;
  margin-top: 10px;
  text-shadow: 3px 3px 0 #000, -1px -1px 0 #000, 1px -1px 0 #000,
    -1px 1px 0 #000, 1px 1px 0 #000;
  letter-spacing: 0.01em;
}

#submit-btn:hover {
  background-color: #a9c0c9;
}

/* Icon Container Styles */
.icon-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-auto-rows: min-content;
  row-gap: 10px;
  column-gap: 10px;
  justify-items: center;
  align-items: start;
  padding: 90px 20px 20px;
  background-color: #333;
  border: 6px solid transparent;
  border-radius: 4px;
  background-clip: padding-box, border-box;
  background-image: url("../../assets/PhoneBG.jpg"),
    linear-gradient(180deg, #bbc449 10%, #239063 5%, #239063 90%, #bbc449 10%);
  background-origin: border-box;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  flex-grow: 1;
  box-sizing: border-box;
  animation: crt-flicker 0.3s infinite;
  box-shadow: 0 4px 10px rgba(255, 255, 255, 0.2),
    inset 0 0 10px rgba(0, 0, 0, 0.7);
}

.icon {
  margin-top: 2px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  color: white;
  font-family: "Born2bSporty FS", sans-serif;
  font-size: 14px;
}

.icon img {
  width: 60px;
  height: 60px;
  border-radius: 12px;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.3);
  filter: contrast(1.1) brightness(1.1) saturate(0.95);
  margin-bottom: 0px;
}

.icon span,
.icon p {
  margin-top: 5px;
  font-size: 14.5px;
  text-shadow: 3px 3px 0 #000, -1px -1px 0 #000, 1px -1px 0 #000,
    -1px 1px 0 #000, 1px 1px 0 #000;
  letter-spacing: 0.01em;
}

/* Animation for Flicker Effect */
@keyframes crt-flicker {
  0%,
  100% {
    opacity: 0.98;
  }
  50% {
    opacity: 0.99;
  }
}

/* Apply Animation to Containers */
.contact-form-container,
.icon-container {
  animation: crt-flicker 0.3s infinite;
  box-shadow: 0 4px 10px rgba(255, 255, 255, 0.2),
    inset 0 0 10px rgba(0, 0, 0, 0.7);
}

/* Media Query for Mobile Devices */
@media (max-width: 1024px) {
  .contact-form-wrapper {
    margin-top: -180px;
  }
}

@media (max-width: 768px) {
  .contact-form-wrapper {
    margin-top: 90px;
  }
}

@media (max-width: 430px) {
  .contact-form-wrapper {
    margin-top: 30px;
    flex-direction: column;
    align-items: center;
    padding: 20px;
  }

  .contact-form-container,
  .icon-container {
    min-width: 100%;
    max-width: 100%;
    min-height: 480px;
    padding: 20px;
  }

  .icon-container {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 10px;
    padding: 60px 10px 10px;
  }

  .contact-form-container h3 {
    font-size: 24px;
  }

  .input-container label {
    font-size: 16px;
  }

  #submit-btn {
    font-size: 20px;
    padding: 8px 20px;
  }

  .icon img {
    width: 50px;
    height: 50px;
  }

  .icon p {
    font-size: 16px;
  }
}

@media (max-width: 390px) {
  .contact-form-wrapper {
    flex-direction: column;
    align-items: center;
    padding: 20px;
  }

  .contact-form-container,
  .icon-container {
    min-width: 100%;
    max-width: 100%;
    min-height: 480px;
    padding: 20px;
  }

  .icon-container {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 10px;
    padding: 60px 10px 10px;
  }

  .contact-form-container h3 {
    font-size: 24px;
  }

  .input-container label {
    font-size: 16px;
  }

  #submit-btn {
    font-size: 20px;
    padding: 8px 20px;
  }

  .icon img {
    width: 50px;
    height: 50px;
  }

  .icon p {
    font-size: 14px;
  }
}
