/* Player Card Area */

:root {
  --color1: rgb(0, 231, 255);
  --color2: rgb(255, 0, 231);
  --sparkles: url("../../assets/sparkles.gif");
  --holo: url("../../assets/holo.png");
  --space: 5%;
  --angle: 133deg;
  --imgsize: 1000px;
  --mx: 50%;
  --my: 50%;
  --posx: 50%;
  --posy: 50%;
}

.card-container {
  position: relative;
  width: 238.11px;
  height: auto;
  margin-top: 20px;
  margin-left: 20px;
  margin-right: 20px;

  overflow: hidden;
  border-radius: 10px;
  transition: transform 0.5s ease, box-shadow 0.2s ease;
  will-change: transform, filter;
  background-color: #040712;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  transform-origin: center;
  box-shadow: -3px -3px 3px 3px var(--color1), 3px 3px 3px 3px var(--color2),
    -5px -5px 10px -5px var(--color1), 5px 5px 10px -5px var(--color2),
    0 0 10px rgba(255, 255, 255, 0.3), 0 0 20px rgba(0, 0, 0, 0.5);
  cursor: zoom-in;
}

.card-container img {
  display: block;
  width: 100%;
  height: 100%;
  z-index: 1;
  position: relative;
  border-radius: 10px;
}

.card-container::before,
.card-container::after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background-repeat: no-repeat;
  opacity: 0.5;
  transition: all 0.33s ease;
  pointer-events: none; /* Ensure the pseudo-elements are not blocking interactions */
  z-index: 2; /* Ensure the pseudo-elements are above the image */
}

.card-container::before {
  background-position: var(--grad-pos, 50% 50%);
  background-size: 300% 300%;
  background-image: linear-gradient(
    115deg,
    transparent 0%,
    var(--color1) 25%,
    transparent 47%,
    transparent 53%,
    var(--color2) 75%,
    transparent 100%
  );
  opacity: 0.88;
  filter: brightness(0.66) contrast(1.33);
  mix-blend-mode: color-dodge;
}

.card-container::after {
  opacity: var(--opacity, 1);
  background-image: var(--sparkles), var(--holo),
    repeating-linear-gradient(
      0deg,
      rgb(255, 119, 115) calc(var(--space) * 1),
      rgba(255, 237, 95, 1) calc(var(--space) * 2),
      rgba(168, 255, 95, 1) calc(var(--space) * 3),
      rgba(131, 255, 247, 1) calc(var(--space) * 4),
      rgba(120, 148, 255, 1) calc(var(--space) * 5),
      rgb(216, 117, 255) calc(var(--space) * 6),
      rgb(255, 119, 115) calc(var(--space) * 7)
    ),
    repeating-linear-gradient(
      var(--angle),
      #0e152e 0%,
      hsl(180, 10%, 60%) 3.8%,
      hsl(180, 29%, 66%) 4.5%,
      hsl(180, 10%, 60%) 5.2%,
      #0e152e 10%,
      #0e152e 12%
    ),
    radial-gradient(
      farthest-corner circle at var(--mx) var(--my),
      rgba(0, 0, 0, 0.1) 12%,
      rgba(0, 0, 0, 0.15) 20%,
      rgba(0, 0, 0, 0.25) 120%
    );
  background-blend-mode: overlay, normal, screen, hue, hard-light;
  background-size: 160%, 160%, var(--imgsize, 100%), 200% 700%, 300%, 200%;
  background-position: var(--sprk-pos, 50% 50%), center, 0% var(--posy),
    var(--posx) var(--posy), var(--posx) var(--posy);
  filter: brightness(1) contrast(1);
  mix-blend-mode: color-dodge;
}

.card-container.active::after,
.card-container:hover::after {
  filter: brightness(1) contrast(1);
  opacity: 1;
}

.card-container.active,
.card-container:hover {
  animation: none;
  transition: box-shadow 0.1s ease-out;
}

.card-container.active::before,
.card-container:hover::before {
  animation: none;
  background-image: linear-gradient(
    110deg,
    transparent 25%,
    var(--color1) 48%,
    var(--color2) 52%,
    transparent 75%
  );
  background-position: var(--grad-pos, 50% 50%);
  background-size: 250% 250%;
  opacity: 0.88;
  filter: brightness(0.66) contrast(1.33);
  mix-blend-mode: color-dodge;
  transition: none;
}

.card-id {
  position: absolute;
  top: 5px;
  left: 11px;
  background: -webkit-linear-gradient(#cfcfcf, #000000);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-size: 100%;
  letter-spacing: 0em;

  font-family: "Permanent Marker";
  font-size: 17px;
  -webkit-text-stroke: 1px black;
  /*-webkit-text-fill-color: rgb(194, 185, 185);
  padding: 5px 8px; /* Padding around the numeral */
  border-radius: 4px;
  pointer-events: none;
  z-index: 1;
}

@keyframes holoCard {
  0%,
  100% {
    transform: rotateZ(0deg) rotateX(0deg) rotateY(0deg);
  }
  5%,
  8% {
    transform: rotateZ(0deg) rotateX(6deg) rotateY(-20deg);
  }
  13%,
  16% {
    transform: rotateZ(0deg) rotateX(-9deg) rotateY(32deg);
  }
  35%,
  38% {
    transform: rotateZ(3deg) rotateX(12deg) rotateY(20deg);
  }
  55% {
    transform: rotateZ(-3deg) rotateX(-12deg) rotateY(-27deg);
  }
}

/* Group Container */

* {
  box-sizing: border-box;
}

.group-container {
  max-width: 900px;
  min-width: 700px;
  background: #111111f2;
  border: 1px solid black;
  border: 2px solid #333;

  margin: 20px;
  padding: 10px;
  border-radius: 15px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 20px;
}

.title-row,
.image-row,
.type-row,
.player-stats-row {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 20px;
}

.image-row,
.secondary-image-row {
  display: flex;
  justify-content: space-around;
  margin-bottom: 10px;
  width: 100%;
}
.title-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  display: flex;
  flex-wrap: inherit;
  justify-content: center;
  align-content: baseline;
  width: 100%;
  text-align: center;
  margin-top: 10px;
}

.title-row {
  display: flex;
  flex-wrap: inherit;
  justify-content: center;
  align-content: baseline;
  width: 100%;
  text-align: center;
  margin-top: 10px;
}

.title-bar {
  height: 2em;
  margin: 0 20px;
  width: 100%;
}

.title-text {
  font-family: "Born2bSporty FS";
  color: #f8f9fa; /* White text */
  font-weight: bold;
  text-transform: uppercase;
  text-shadow: 3px 3px 0 #000, -1px -1px 0 #000, 1px -1px 0 #000,
    -1px 1px 0 #000, 1px 1px 0 #000;
  letter-spacing: 0.01em;
  text-align: center;
  justify-content: center;
}

.secondary-title {
  letter-spacing: 0.05em;
}

/* Photo box & Light box */
.photo-box img {
  width: 100%;
  height: auto;
  display: block;
}
.lightbox {
  position: fixed;
  cursor: zoom-in;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1050;
  cursor: pointer;
}

.lightbox img {
  max-width: 90%;
  max-height: 90%;
  cursor: zoom-out;
}

/* Type Container */

.type-row {
  display: flex;
  box-sizing: border-box;
  border-bottom: none;
  overflow: hidden;
  justify-content: space-around;
  width: 100%;
  align-items: center;
  margin-top: 10px;
}
.type-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 200px;

  border-radius: 8px;
  margin: 5px;
  box-sizing: border-box;
}

.type-item {
  border: 1px solid black;
  width: 68px;
  height: 20px;
  margin: 0 2px 0 2px; /* Space between type items */
  display: flex;
  justify-content: center;
  color: white;
  font-family: "Pokemon Fire Red", sans-serif;
  font-size: 16px;
  text-shadow: 1px 0px 0px black,
    /* Bottom-right dark shadow */ 0px 1px 0px black,
    /* Directly underneath shadow */ 1px 1px 0px black;
  border-radius: 5px;
}

.group-text-box {
  background-color: #f8f9fa;
  padding: 10px;
  margin: 20px;
  width: 90%;

  text-align: left;
}

/* Player stats for groups */

.player-stats-row {
  display: flex;
  justify-content: space-around;
  width: 100%;
}

.stats-container {
  padding: 2px;
  width: 238.11px;
  border-radius: 8px;
  border: 2px solid;
  margin-top: 0px;
  margin: 0px;
}

.stats-label {
  text-align: center;
  font-family: "Pokemon Fire Red", sans-serif;
  font-size: 25px;
  color: white;
  text-shadow: 2px 0px 0px black,
    /* Bottom-right dark shadow */ 0px 2px 0px black,
    /* Directly underneath shadow */ 2px 2px 0px black;
  font-weight: bold;
  border: 1px solid;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  border-bottom: none;
  padding: 5px 0px 7px 0px;
  margin: 0 auto;
}

.stat-label-container {
  border-right: 1px solid;
}

.stat-row {
  width: 100%;

  display: flex;
  border: 1px solid;
  box-sizing: border-box;
  border-bottom: none;
  overflow: hidden;
  align-items: center;
}

.stat-row:nth-last-child(2) ~ .stat-row {
  border-bottom: 1px solid;
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
}

.stat-label-container {
  width: 100px;

  border-right: 1;
  height: 26px;
  text-transform: none;
}

.stat-label {
  margin-left: 4px;
  color: white;
  font-family: "Pokemon Fire Red", sans-serif;
  font-size: 18px;
  text-shadow: 1px 0px 0px black,
    /* Bottom-right dark shadow */ 0px 1px 0px black,
    /* Directly underneath shadow */ 1px 1px 0px black;
  text-align: left;

  text-transform: none;
}

.stat-value {
  margin-left: 0px;
  color: white;
  font-family: "Pokemon Fire Red", sans-serif;
  font-size: 18px;
  text-shadow: 1px 0px 0px black,
    /* Bottom-right dark shadow */ 0px 1px 0px black,
    /* Directly underneath shadow */ 1px 1px 0px black;
  text-align: right;
  float: right;
  margin-right: 4px;
  overflow: hidden;
  text-transform: none;
}

.stat-bar-container {
  flex: 1;
  align-items: center;
}

.stat-bar {
  height: 26px;
  border-left: 0px solid;
  flex-grow: 1;
  position: relative;
  box-shadow: none;
}

.stat-fill {
  height: 92%;
  border: 1px solid;
  margin-left: 1px;
  width: 0%; /* Initial width, think I'll set this dynamically */
  position: absolute;
  margin-top: 1px;
  top: 0;
  left: 0;
  box-shadow: none;
}

/* Group footer for illustrators */

.groupFooter {
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: #215e6e;
  border-radius: 10px;
  border: 2px solid #16414d;
  max-width: 80%;
  margin: 0 auto;
  margin-bottom: 10px;
  font-family: "Pokemon Fire Red", sans-serif;
}

.groupFooter a {
  margin-right: 8px;
  display: flex;
  align-items: center;
  text-align: center;
  text-decoration: none;
  color: inherit;
  font-size: 15px;
  color: white;
}

.groupFooter img.instagram-logo {
  width: 20px;
  margin-top: 3px;
  margin-bottom: 3px;
  height: 100%;
  margin-left: 8px;
  margin-right: 8px;
}

.groupFooter img.group-illustration {
  width: 30px;
  height: auto;
  margin-top: 3px;
  margin-bottom: 3px;
  margin-left: 10px;
  border-radius: 50%;
}

@media (max-width: 1024px) {
  .container {
    margin-top: 60px;
  }
  .group-container {
    width: 100%;
  }
}
@media (max-width: 820px) {
  .container {
    margin-top: 60px;
  }
  .group-container {
    width: 100%;
  }
}

@media (max-width: 768px) {
  .container {
    margin-top: 80px; /* Stacks player rows vertically */
  }
  .group-container {
    width: 100%;
  }
}

@media (max-width: 480px) {
  .container {
    margin-top: 30px; /* Stacks player rows vertically */
  }
  .group-container {
    display: flex;
    flex-direction: column; /* Stacks player rows vertically */
  }
}

@media (max-width: 390px) {
  .container {
    margin-top: 30px; /* Stacks player rows vertically */
  }
  .group-container {
    display: flex;
    flex-direction: column; /* Stacks player rows vertically */
  }

  .title-row,
  .image-row,
  .type-row,
  .player-stats-row {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; /* Makes sure each player's components are vertically aligned */
  }

  .title-row .title-bar,
  .image-row .image-card,
  .type-row .type-container,
  .player-stats-row .player-stats {
    width: 100%; /* Ensures the components take up the full width in mobile */
  }
  .title-row {
    display: flex;
    justify-content: center; /* Centers content horizontally */
    align-items: center; /* Centers content vertically if needed */
    text-align: center; /* Ensures text is centered */
    margin: 0 auto; /* Adjusts automatic margins to center the whole row */
    width: 100%; /* Ensure it takes up the full width of the screen */
  }
  .title-bar {
    height: 2em;
    margin: 0 20px;
    width: 100%;
    align-items: center;
    justify-content: center;
  }
}
