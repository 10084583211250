/* Keep the circular switch container */
.theme-switch-container {
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 150px;
  height: 50px;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  cursor: pointer;
  z-index: 1000;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

/* Light Mode Styles */
body.light-mode .theme-switch-container {
  background-color: #2b8378;
  background-image: linear-gradient(#3ba296 50%, transparent 50%);
  background-size: 100% 2px;
  background-position: 0 0, 0 8px;
  border: 1px solid black;
}

/* Dark Mode Styles */
body.dark-mode .theme-switch-container {
  background-color: rgba(0, 0, 0, 0.8);
  border: 1px solid black;
}

/* Retro Mode Styles */
body.retro-mode .theme-switch-container {
  background-color: #cdcace;
  border: 1px solid black;
}

.theme-option img {
  width: 30px;
  height: 30px;
  cursor: pointer;
  transition: transform 0.3s ease, filter 0.3s ease;
}

.theme-option img.selected {
  transform: scale(1.2);
}

.theme-option.dark-option img.selected {
  filter: brightness(1.5) invert(1);
}
.theme-option.dark-option img {
  filter: brightness(0.5) invert(0);
}
.theme-option.light-option img.selected {
  filter: brightness(1) invert(0);
}
.theme-option.light-option img {
  filter: brightness(0.3) invert(0);
}
.theme-option.retro-option img.selected {
  filter: brightness(1) invert(0);
}
.theme-option.retro-option img {
  filter: brightness(0.5) invert(0);
}
/* Responsive adjustments */
@media (max-width: 1020px) {
  .theme-switch-container {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: auto;
  }
  .theme-switch-container {
    position: fixed;
    bottom: 20px;
    right: 2px;
    width: 25px;
    height: 100px;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    cursor: pointer;
    z-index: 1000;
    transition: background-color 0.3s ease, transform 0.3s ease;
  }
  .theme-option img {
    width: 15px;
    height: 15px;
  }
}
