.sidebar-right {
  max-height: 500px; /* or a value you prefer, e.g., 80vh */
  width: 250px;
  border-radius: 12px;
  margin-right: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
  background-color: #2b8378;
  background-image: linear-gradient(#3ba296 50%, transparent 50%);
  background-size: 100% 2px;
  background-position: 0 0, 0 8px;
  position: fixed;
  top: 0;
  right: 0;
  transition: transform 0.5s ease-in-out;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  padding: 20px;
  z-index: 1000;
  overflow-y: auto;
  border: 4px solid #1e5f57;
  box-sizing: border-box;
  padding-bottom: 20px;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.3);
}
.sidebar-right::-webkit-scrollbar-track:end:decrement {
  height: 50%;
  display: block;
}
.sidebar-right::-webkit-scrollbar-track:start:increment {
  height: 50%;
  background: transparent;
}
.sidebar-right-title {
  font-size: 34px;
  margin-top: -8px;
  font-weight: bold;
  margin-bottom: -28px;

  text-align: center;
  color: #fff;
  font-family: "Pokemon Fire Red", sans-serif;

  text-shadow: 2px 0px 0px black,
    /* Bottom-right dark shadow */ 0px 2px 0px black,
    /* Directly underneath shadow */ 2px 2px 0px black;
}

.news-card {
  background-color: #4d9be3;
  background-image: linear-gradient(
    to bottom,
    #87d6ff 0%,
    #87d6ff 2%,
    #4d9be3 2%,

    #4d9be3 86%,
    #87d6ff 86%,

    #87d6ff 88%,
    #4d9be3 88%,

    #4d9be3 90%,
    #87d6ff 90%,
    #87d6ff 100%
  );
  background-size: 100% 100%;
  border-radius: 12px;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.3);
  padding: 10px;
  padding-bottom: 4px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  transition: border 0.3s ease, transform 0.3s ease;
  border: 2px solid #375f8b;
  flex-shrink: 0;
}

.news-card:hover {
  border-color: #e64040;
  background-color: #7bd7ee;
  background-image: linear-gradient(
    to bottom,
    #ffffce 0%,
    #ffffce 2%,
    #efe7ad 2%,

    #efe7ad 86%,
    #ffffce 86%,

    #ffffce 88%,
    #efe7ad 88%,

    #efe7ad 90%,
    #ffffce 90%,
    #ffffce 100%
  );
  transform: translateY(-5px);
}

.news-bar {
  width: 90%;
  height: 10px;
  background-color: #c1844e;
  margin: 10px auto 0 auto;
  border-radius: 5px;
}

.news-container {
  width: 100%;
  background-color: #e7c16f;
  border: 2px solid #7b6340;
  border-radius: 4px;
  padding: 10px;
  position: relative;
  margin-bottom: 20px;
  box-shadow: inset 0px -3px 0px #c1844e, 0px 0px 8px rgba(0, 0, 0, 0.3);
}

.news-card-content h1 {
  margin-top: 8px;
  letter-spacing: 0.02em;
  margin-bottom: 11px;
  color: white;
  font-family: "Pokemon Fire Red", sans-serif;
  font-size: 18px;
  text-shadow: 1px 0px 0px black,
    /* Bottom-right dark shadow */ 0px 1px 0px black,
    /* Directly underneath shadow */ 1px 1px 0px black;
}

.news-card-content p {
  margin-top: 1px;
  color: white;
  width: 78%;
  font-family: "Pokemon Fire Red", sans-serif;
  font-size: 18px;
  text-shadow: 1px 0px 0px black,
    /* Bottom-right dark shadow */ 0px 1px 0px black,
    /* Directly underneath shadow */ 1px 1px 0px black;
  margin-bottom: 5px;
}

.news-btn {
  font-family: "Pokemon Fire Red", sans-serif;

  background-color: #755ab5;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  padding-bottom: 2px;
  padding-right: 4px;
  padding-left: 4px;
  text-transform: uppercase;
  align-items: center;
  font-size: 12px;
  transition: background-color 0.3s ease;
  align-self: flex-start;
}

.news-btn:hover {
  background-color: #e64040;
}

.news-card img {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #ddd;
  border: 1px solid #755ab5;
}

/* Dark mode */

.dark-mode {
  .sidebar-right {
    max-height: 500px;
    width: 250px;
    border-radius: 12px;
    margin-right: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
    background-color: #181818;
    background-image: linear-gradient(#000000 50%, transparent 50%);
    background-size: 100% 2px;
    background-position: 0 0, 0 8px;
    position: fixed;
    top: 0;
    right: 0;
    transition: transform 0.5s ease-in-out;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    padding: 20px;
    z-index: 1000;
    overflow-y: auto;
    border: 2px solid #333;
    box-sizing: border-box;
    padding-bottom: 20px;
    box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.3);
  }

  .sidebar-right-title {
    font-size: 34px;
    margin-top: -8px;
    font-weight: bold;
    margin-bottom: -28px;

    text-align: center;
    color: #fff;
    font-family: "Pokemon Fire Red", sans-serif;

    text-shadow: 2px 0px 0px black,
      /* Bottom-right dark shadow */ 0px 2px 0px black,
      /* Directly underneath shadow */ 2px 2px 0px black;
  }

  .news-card {
    background-color: #111;
    background-image: linear-gradient(
      to bottom,
      #111 0%,
      #111 2%,
      #222 2%,

      #222 86%,
      #111 86%,

      #111 88%,
      #222 88%,

      #222 90%,
      #111 90%,
      #111 100%
    );
    background-size: 100% 100%;
    border-radius: 12px;
    box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.3);
    padding: 10px;
    padding-bottom: 4px;
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    transition: border 0.3s ease, transform 0.3s ease;
    border: 2px solid #2c2c2c;
    overflow: hidden;
  }

  .news-card:hover {
    border-color: #2c2c2c;
    background-color: #2e7db6;
    background-image: linear-gradient(
      to bottom,
      #2e7db6 0%,
      #2e7db6 2%,
      #509eb3 2%,

      #509eb3 86%,
      #2e7db6 86%,

      #2e7db6 88%,
      #509eb3 88%,

      #509eb3 90%,
      #2e7db6 90%,
      #2e7db6 100%
    );
    transform: translateY(-5px);
  }

  .news-bar {
    width: 90%;
    height: 10px;
    background-color: #2e7db6;
    margin: 10px auto 0 auto;
    border-radius: 5px;
  }

  .news-container {
    width: 100%;
    background-color: #509eb3;
    border: 2px solid #2e7db6;
    border-radius: 4px;
    padding: 10px;
    position: relative;
    margin-bottom: 20px;
    box-shadow: inset 0px -3px 0px #2e7db6, 0px 0px 8px rgba(0, 0, 0, 0.3);
  }

  .news-card-content h1 {
    margin-top: 8px;
    letter-spacing: 0.02em;
    margin-bottom: 11px;
    color: white;
    font-family: "Pokemon Fire Red", sans-serif;
    font-size: 18px;
    text-shadow: 1px 0px 0px black,
      /* Bottom-right dark shadow */ 0px 1px 0px black,
      /* Directly underneath shadow */ 1px 1px 0px black;
  }

  .news-card-content p {
    margin-top: 1px;
    color: white;
    width: 78%;
    font-family: "Pokemon Fire Red", sans-serif;
    font-size: 18px;
    text-shadow: 1px 0px 0px black,
      /* Bottom-right dark shadow */ 0px 1px 0px black,
      /* Directly underneath shadow */ 1px 1px 0px black;
    margin-bottom: 5px;
  }

  .news-btn {
    font-family: "Pokemon Fire Red", sans-serif;

    background-color: #2e7db6;
    color: rgb(255, 255, 255);
    border: none;
    border-radius: 5px;
    cursor: pointer;
    padding-bottom: 2px;
    padding-right: 4px;
    padding-left: 4px;
    text-transform: uppercase;
    align-items: center;
    font-size: 12px;
    transition: background-color 0.3s ease;
    align-self: flex-start;
  }

  .news-btn:hover {
    background-color: #4734f5;
  }

  .news-card img {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #111;
    border: 1px solid #2e7db6;
  }
}

/* Mobile style stuff */

.sidebar-right-toggle {
  position: fixed;
  top: 18%;
  right: 0;
  width: 16px;
  height: 100px;
  background-color: #d1af48;
  border: 2px solid #7b6340;
  border-right: 0px;
  cursor: pointer;
  transition: transform 0.5s ease-in-out;
  z-index: 1001;
}

.sidebar-tab {
  writing-mode: vertical-lr;
  text-align: center;
  margin-top: 16px;
  padding: 1px;
  font-size: 18px;
  color: white;
  letter-spacing: 0.05em;
  font-family: "Pokemon Fire Red", sans-serif;
  text-shadow: 1px 1px 0px black;
  font-style: bold;
}

.sidebar-right-toggle.open {
  transform: translateX(-250px);
  width: 20px;
  padding: 1px;

  /* Move it out when sidebar is open */
}

.sidebar-tab.open {
  padding: 2px;
}

.sidebar-right.mobile {
  top: 10%;

  width: 250px;
  border-radius: 12px;
  margin-right: 0px;
  margin-top: 10px;
  margin-bottom: 10px;
  background-color: #2b8378;
  background-image: linear-gradient(#3ba296 50%, transparent 50%);
  background-size: 100% 2px;
  background-position: 0 0, 0 8px;
  position: fixed;
  right: 0;
  display: flex;
  flex-direction: column;
  transition: transform 0.5s ease-in-out;

  z-index: 1000;
  overflow-y: auto;
  border: 4px solid #1e5f57;
  box-sizing: border-box;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.3);
}

.sidebar-right-hidden {
  transform: translateX(100%);
}

/* Visible State */
.sidebar-right-visible {
  transform: translateX(0%);
}

/* Button Style */
.sidebar-toggle {
  position: fixed;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  background-color: #2b8378;
  padding: 10px;
  border-radius: 5px 0 0 5px;
  cursor: pointer;
}

.dark-mode {
  .sidebar-right-toggle {
    position: fixed;
    top: 18%;
    right: 0;
    width: 16px;
    height: 100px;
    background-color: #509eb3;
    border: 2px solid #2e7db6;
    border-right: 0px;
    cursor: pointer;
    transition: transform 0.5s ease-in-out;
    z-index: 1001;
  }

  .sidebar-tab {
    writing-mode: vertical-lr;
    text-align: center;
    margin-top: 16px;
    padding: 1px;
    font-size: 18px;
    color: white;
    letter-spacing: 0.05em;
    font-family: "Pokemon Fire Red", sans-serif;
    text-shadow: 1px 1px 0px black;
    font-style: bold;
  }

  .sidebar-right-toggle.open {
    transform: translateX(-250px);
    width: 20px;
    padding: 1px;

    /* Move it out when sidebar is open */
  }

  .sidebar-tab.open {
    padding: 2px;
  }

  .sidebar-right.mobile {
    top: 10%;

    width: 250px;
    border-radius: 12px;
    margin-right: 0px;
    margin-top: 10px;
    margin-bottom: 10px;
    background-color: #1f1f1f;
    background-image: linear-gradient(#1f1f1f 50%, transparent 50%);
    background-size: 100% 2px;
    background-position: 0 0, 0 8px;
    position: fixed;
    right: 0;
    display: flex;
    flex-direction: column;
    transition: transform 0.5s ease-in-out;

    z-index: 1000;
    overflow-y: auto;
    border: 2px solid #111;
    box-sizing: border-box;
    box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.3);
  }

  .sidebar-right-hidden {
    transform: translateX(100%);
  }

  /* Visible State */
  .sidebar-right-visible {
    transform: translateX(0%);
  }

  /* Button Style */
  .sidebar-toggle {
    position: fixed;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    background-color: #111;
    padding: 10px;
    border-radius: 5px 0 0 5px;
    cursor: pointer;
  }
}

.retro-mode {
  .sidebar-right {
    max-height: 500px;
    width: 250px;
    border-radius: 12px;
    margin-right: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
    background-color: #181818;
    background-image: linear-gradient(#000000 50%, transparent 50%);
    background-size: 100% 2px;
    background-position: 0 0, 0 8px;
    position: fixed;
    top: 0;
    right: 0;
    transition: transform 0.5s ease-in-out;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    padding: 20px;
    z-index: 1000;
    overflow-y: auto;
    border: 2px solid #333;
    box-sizing: border-box;
    padding-bottom: 20px;
    box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.3);
  }
  .news-btn {
    color: #e0dfd9;
  }
  .news-btn:hover {
    background-color: #e0dfd9;
    color: black;
  }
}
