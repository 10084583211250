.light-mode {
  #mc_embed_shell {
    font-family: "Pokemon Fire Red", sans-serif;
    background: #b82e26;
    color: white;
    max-height: 150px;
    margin-bottom: 10px;
    border-radius: 12px;
    border: 2px solid #49111e;
  }
  #mc_embed_signup form {
    margin: 8px 10px 8px 10px;
  }
  #mc_embed_signup {
    padding: 0px;
    border-radius: 10px;
    margin-top: 0px;
  }

  #mc_embed_signup h2 {
    font-size: 20px;
    color: white;
    margin-bottom: 2px;
    margin-top: 0px;
  }
  #mc_embed_signup label {
    font-size: 16px;
    color: white;
  }
  #mc_embed_signup #mce-EMAIL {
    font-size: 10px;
  }
  #mc_embed_signup input {
    width: 100%;
    padding: 0px;
    margin-bottom: 0px;
    color: black;
    background-color: white;
    border: 1px solid #111;
    border-radius: 5px;
  }

  #mc_embed_signup .button {
    font-family: "Pokemon Fire Red", sans-serif;
    font-style: bold;
    background-color: #755ab5;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px;
    height: 15px;
    width: 80px;
    line-height: 15px;
    padding-bottom: 2px;
    padding-right: 4px;
    padding-left: 4px;
    text-transform: uppercase;
    align-items: center;
  }

  #mc_embed_signup .button:hover {
    background-color: #e64040;
  }
}

.dark-mode {
  #mc_embed_shell {
    font-family: "Pokemon Fire Red", sans-serif;
    background: #755ab5;
    color: white;
    max-height: 150px;
    margin-bottom: 10px;
    border-radius: 12px;
    border: 2px solid #250c5f;
  }
  #mc_embed_signup form {
    margin: 8px 10px 8px 10px;
  }
  #mc_embed_signup {
    padding: 0px;
    border-radius: 10px;
    margin-top: 0px;
  }

  #mc_embed_signup h2 {
    font-size: 20px;
    color: white;
    margin-bottom: 2px;
    margin-top: 0px;
  }
  #mc_embed_signup label {
    font-size: 16px;
    color: white;
  }
  #mc_embed_signup #mce-EMAIL {
    font-size: 10px;
  }
  #mc_embed_signup input {
    width: 100%;
    padding: 0px;
    margin-bottom: 0px;
    color: black;
    background-color: white;
    border: 1px solid #111;
    border-radius: 5px;
  }

  #mc_embed_signup .button {
    font-family: "Pokemon Fire Red", sans-serif;
    font-style: bold;
    background-color: #2e7db6;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px;
    height: 15px;
    width: 80px;
    line-height: 15px;
    padding-bottom: 2px;
    padding-right: 4px;
    padding-left: 4px;
    text-transform: uppercase;
    align-items: center;
  }

  #mc_embed_signup .button:hover {
    background-color: #4734f5;
  }
}

.retro-mode {
  #mc_embed_shell {
    font-family: "Pokemon Fire Red", sans-serif;
    background: #80c07d;
    max-height: 150px;
    margin-bottom: 10px;
    border-radius: 12px;
    border: 2px solid #2a352b;
  }
  #mc_embed_signup form {
    margin: 8px 10px 8px 10px;
  }
  #mc_embed_signup {
    padding: 0px;
    border-radius: 10px;
    margin-top: 0px;
  }

  #mc_embed_signup h2 {
    font-size: 20px;
    color: #2a2c28;
    margin-bottom: 2px;
    margin-top: 0px;
  }
  #mc_embed_signup label {
    font-size: 16px;
    color: #2a2c28;
  }
  #mc_embed_signup #mce-EMAIL {
    font-size: 10px;
  }
  #mc_embed_signup input {
    width: 100%;
    padding: 0px;
    margin-bottom: 0px;
    color: #2a2c28;
    background-color: #e0dfd9;
    border: 1px solid #111;
    border-radius: 5px;
  }

  #mc_embed_signup .button {
    font-family: "Pokemon Fire Red", sans-serif;
    font-style: bold;
    background-color: #2a2c28;
    color: #e0dfd9;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px;
    height: 15px;
    width: 80px;
    line-height: 15px;
    padding-bottom: 2px;
    padding-right: 4px;
    padding-left: 4px;
    text-transform: uppercase;
    align-items: center;
  }

  #mc_embed_signup .button:hover {
    background-color: #e0dfd9;
    color: #2a2c28;
  }
}
